import { makeStyles } from "@material-ui/core";

const screenSize = window.screen.width;

const useStyles = makeStyles((theme) => ({
    primaryText: {
        color: theme.palette.primary.main
    },
    secondaryText: {
        color: theme.palette.secondary.main
    },
    mainFeaturedPost: {
        height: screenSize>380 ? 350 : 250,
        flex: 1,
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
      overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
      },
      mainFeaturedPostContent: {
        textAlign: 'center',
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
          paddingTop: theme.spacing(2),
          paddingLeft: theme.spacing(4),
          paddingRight: 0,
        },
      },
      weatherTemp: {
        textAlign: 'left',
        borderLeftWidth: 2, 
        borderLeftStyle: 'solid', 
        height: 100, 
        marginTop: 20, 
        paddingLeft: 20
      }
}));

export default useStyles;