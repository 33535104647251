import { Grid, Typography, Card, CardActions, CardContent, CardActionArea, CardMedia, FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchZonesByUser } from '../../../redux/actions/zoneActions';
import farmImage from './img/farm.png'
import useStyles from './style';

const Zones = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const zones = useSelector((state) => state.zones.zones.data);

  useEffect(()=>{
    dispatch(fetchZonesByUser(user.user._id));
  },[]);

  return (
    <Grid container spacing={3}>
      {zones!==undefined 
      ? zones.map((zone)=>(
        <Grid item xs={12} sm={6} md={4} lg={3} key={zone._id}>
          <Card className={classes.card}>
            <CardActionArea onClick={()=> navigate(`/zones/${zone._id}`)}>
              <CardMedia
                className={classes.media}
                image={farmImage}
                title={zone.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {zone.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" noWrap>
                  {zone.description}
                </Typography>
                <Grid container spacing={3} className={classes.contentBox}>
                  <Grid item xs={6}>
                    <Typography variant="body1" color="textPrimary" component="p">
                      Irrigation {zone.irrigMode === 'man' ? ('Manuelle') : ('Automatique')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" color='textPrimary' component="p">
                      Surface : <b className={classes.primaryMainText}>{zone.area}m²</b>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <FormGroup>
              <FormControlLabel
                control={<Switch checked={true} name={`switch${zone._id}`} color='primary' />}
                label="Vannes Ouvertes"
              />
              </FormGroup>
              <Link className={classes.link} to={`/zones/${zone._id}`}>
                <div className={classes.detailBtn}>
                  <Typography variant="body1" component="p">
                    Détails
                  </Typography>
                </div>
              </Link>
            </CardActions>
          </Card>
        </Grid>
      ))
      : (<div>Loading...</div>)
    }
    </Grid>
  )
}

export default Zones;