import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import MailIcon from '@material-ui/icons/Mail';
import React, { useEffect, useState } from 'react';
import bgImg from '../users/img/bg-nature.jpg';
import useStyles from './style';
import { MapContainer, Polygon, TileLayer } from 'react-leaflet';

export const FieldDetails = ({showIt, selectedField}) => {
  const classes = useStyles();
  const [field, setField] = useState(selectedField);
  const [center, setCenter] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [showUserDetails, setShowUserDetails] = useState(false);
  useEffect(()=>{
      setField(selectedField);
  },[selectedField])

  useEffect(()=>{
    field.geoLimits && field.geoLimits.length > 0 && setCenter([field.geoLimits[0].coordinates[0], field.geoLimits[0].coordinates[1]]);
    field.geoLimits && field.geoLimits.length > 0 && field.geoLimits.map((geoPoint) => {
      setCoordinates(coordinates => [...coordinates, geoPoint.coordinates])
    })
  },[selectedField])

  return (
    <Card>
      <CardActionArea>
        <CardMedia
          className={classes.detailsBgImg}
          image={bgImg}
        />
        <CardContent>
          <div className={classes.detailsTitle}>
            <Typography gutterBottom variant="h5" component="h2">
                {field.title}
            </Typography>
            <div className={classes.detailsUser} onClick={()=>setShowUserDetails(!showUserDetails)}>
              <Typography variant="h6" color="textSecondary" 
                style={{textTransform: 'capitalize', cursor: 'pointer'}} 
              >
                  {field.user.firstname} {field.user.lastname}
              </Typography>
              {showUserDetails ? (
                <VisibilityOffIcon color='primary' style={{marginLeft: 10}}/>
              ) : (
                <VisibilityIcon color='primary' style={{marginLeft: 10}}/>
              )}
            </div>
          </div>
          <div className={classes.detailsContent}>
              <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <div className={classes.infoRow}>
                        <LocationOnIcon color='primary' />
                        <div className={classes.infoBlock}>
                            <Typography  variant="body1">
                                {field.country}, {field.state}
                            </Typography>
                            <Typography  variant="body1">
                                {field.city}, {field.address}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.infoRow}>
                        <AspectRatioIcon color='primary' />
                        <Typography className={classes.infoText} variant="body1">Area : {field.area}Ha</Typography>
                    </div>
                    <Typography variant="h6" color={`${field.isActive ? ('primary') : ('textSecondary')}`}>
                        {field.isActive ? ('Active') : ('Blocked')}
                    </Typography>
                  </Grid>
                  {showUserDetails && (
                  <Grid item xs={12} md={6}>
                    <div className={classes.infoRow}>
                        <MailIcon color='primary' />
                        <Typography className={classes.infoText} variant="body1">{field.user.email}</Typography>
                    </div>
                    <div className={classes.infoRow}>
                        <LocationOnIcon color='primary' />
                        <div className={classes.infoBlock}>
                            <Typography  variant="body1">
                                {field.user.country}, {field.user.state}
                            </Typography>
                            <Typography  variant="body1">
                                {field.user.city} {field.user.postcode}, {field.user.address}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.infoRow}>
                        <PhoneIcon color='primary' />
                        <Typography className={classes.infoText} variant="body1">{field.user.phone}</Typography>
                    </div>
                  </Grid>
                  )}
                  {center && coordinates && coordinates.length>0 && (
                  <Grid item xs={12}>
                    <MapContainer
                      center={center}
                      zoom={13}
                      style={{width: '100%', height: 400, borderRadius: 5}}
                    >
                      <TileLayer
                        url='https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=C3lguWuPKbIT8I8tTO7t'
                        attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                      />
                      <Polygon
                        pathOptions={{
                          weight: 2,
                          opacity: 1,
                          dashArray: 3,
                          color: '#105157',
                          fillColor: '#31BC85',
                          fillOpacity: 0.7,
                        }}
                        positions={coordinates}
                      />
                    </MapContainer>
                  </Grid>
                  )}
              </Grid>
            
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={()=>showIt(false)}>
          Close
        </Button>
      </CardActions>
    </Card>
  )
}
