import { Button, FormControl, FormControlLabel, FormGroup, Grid, Input, InputAdornment, InputLabel, Paper, Switch, TextField, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './style';
import Select from 'react-select';
import { addToLora, editVanne, removeFromLora } from '../../../redux/actions/vanneActions';
import { fetchZones } from '../../../redux/actions/zoneActions';
import { fetchLorasByZone } from '../../../redux/actions/loraActions';

const EditVanne = ({showIt, vanne, setAlert}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const zoneLabel = vanne.zone.title + ' | ' + vanne.zone.field.title;
  const loraLabel = vanne.lorabox ? (vanne.lorabox?.title + ' (' + vanne.lorabox?.device + ')') : 'choose LoraBox'
  const [openTranslation, setOpenTranslation] = useState(false);
  const [title, setTitle] = useState(vanne.title);
  const [titleFr, setTitleFr] = useState(vanne.title_tr ? vanne.title_tr.fr : null);
  const [titleAr, setTitleAr] = useState(vanne.title_tr ? vanne.title_tr.ar : null);
  const [selectedZone, setSelectedZone] = useState({ value: vanne.zone, label: zoneLabel});
  const [selectedLora, setSelectedLora] = useState({ value: vanne.lorabox, label: loraLabel});
  const [description, setDescription] = useState(vanne.description);
  const [descriptionFr, setDescriptionFr] = useState(vanne.description_tr ? vanne.description_tr.fr : null);
  const [descriptionAr, setDescriptionAr] = useState(vanne.description_tr ? vanne.description_tr.ar : null);
  const [brand, setBrand] = useState(vanne.brand);
  const [diameter, setDiameter] = useState(vanne.diameter);
  const [debit, setDebit]= useState(vanne.debit);
  const [qty_pd, setQty_pd]= useState(vanne.qty_pd);
  const [nb_pd, setNb_pd]= useState(vanne.nb_pd);
  const [h_diameter, setH_diameter]= useState(vanne.hose.diameter);
  const [nbGouteur, setNbGouteur]= useState(vanne.hose.nbGouteur);
  const [debit_pg, setDebit_pg]= useState(vanne.hose.debit_pg);
  const [isActive, setIsActive] = useState(vanne.isActive);

  const zones = useSelector(state => state.zones.zones.data);
  const loras = useSelector(state => state.loras.loras.data);
  useEffect(() => {
    dispatch(fetchZones());
  }, [zones]);
  useEffect(()=>{
    dispatch(fetchLorasByZone(selectedZone.value._id));
  },[selectedZone]);

  const zoneOptions = [];
  zones && zones.map((zone) => {
    let zoneLabel = zone.title + ' | ' + zone.field.title;
    let option = { value: zone, label: zoneLabel };
    zoneOptions.push(option);
  });

  const loraOptions = [];
  loras && loras.map((lora) => {
    let loraLabel = lora.title + ' (' + lora.device + ')';
    let option = { value: lora, label: loraLabel };
    loraOptions.push(option);
  });
  

  const handleSubmit = (e) => {
    e.preventDefault();
    //delete selectedLora.value._id;
    let lorabox = selectedLora.value ? {
      id: selectedLora.value._id,
      title: selectedLora.value.title,
      macAddr: selectedLora.value.macAddr,
      connected: selectedLora.value.connected,
      device: selectedLora.value.device,
      position: selectedLora.value.position,
      isActive: selectedLora.value.isActive
    } : null;
    const vanneToUpdate = {
      title: e.target.title.value,
      title_tr: {
        en: e.target.title.value,
        fr: e.target.title_fr?.value,
        ar: e.target.title_ar?.value,
      },
      zone: selectedZone.value,
      zoneId: selectedZone.value._id,
      lorabox: lorabox,
      loraboxId: selectedLora.value._id,
      field: selectedZone.value.field._id,
      description: e.target.description.value,
      description_tr: {
        en: e.target.description.value,
        fr: e.target.description_fr?.value,
        ar: e.target.description_ar?.value,
      },
      brand: e.target.brand.value,
      diameter: e.target.diameter.value,
      debit: e.target.debit.value,
      qty_pd: e.target.qty_pd.value,
      nb_pd: e.target.nb_pd.value,
      hose: {
        diameter: e.target.h_diameter.value,
        nbGouteur: e.target.nbGouteur.value,
        debit_pg: e.target.debit_pg.value
      },
      isActive: isActive
    }
    if(vanne.loraboxId && vanne.loraboxId !== selectedLora.value._id)
      dispatch(removeFromLora(vanne.loraboxId, vanne._id))
    dispatch(addToLora(selectedLora.value._id ,vanne._id));
    dispatch(editVanne(vanne._id, vanneToUpdate));
    showIt(false);
    setAlert({
      isOpen: true,
      type: 'success',
      message: 'Vanne edited successfully !'
    })
  }

  return (
    <div>
      <Paper style={{ padding: 30 }}>
        <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
          <Typography variant="h5" color="primary" style={{ marginBottom: 30 }} >
            Edit Vanne
          </Typography>
          <Button onClick={()=>setOpenTranslation(!openTranslation)}><GTranslateIcon color='primary' /></Button>
        </div>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField id="title" name="title" label="Vanne Title" fullWidth value={title} onChange={(e)=>setTitle(e.target.value)} />
            </Grid>
            {openTranslation && (<>
            <Grid item xs={12} md={6}>
              <TextField name="title_fr" label="Vanne Title (FR)" fullWidth value={titleFr} onChange={(e)=>setTitleFr(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="title_ar" label="Vanne Title (AR)" fullWidth value={titleAr} onChange={(e)=>setTitleAr(e.target.value)} />
            </Grid>
            </>)}
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <Typography color='textSecondary'>Zone</Typography>
                <Select
                  defaultValue={selectedZone}
                  onChange={setSelectedZone}
                  options={zoneOptions}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <Typography color='textSecondary'>LoRa Box</Typography>
                <Select
                  defaultValue={selectedLora}
                  onChange={setSelectedLora}
                  options={loraOptions}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField id="description" name="description" label="Description" fullWidth multiline value={description} onChange={(e)=>setDescription(e.target.value)} />
            </Grid>
            {openTranslation && (<>
            <Grid item xs={12}>
              <TextField name="description_fr" label="Vanne Description (FR)" fullWidth value={descriptionFr} onChange={(e)=>setDescriptionFr(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="description_ar" label="Vanne Description (AR)" fullWidth value={descriptionAr} onChange={(e)=>setDescriptionAr(e.target.value)} />
            </Grid>
            </>)}
            <Grid item xs={12} md={4}>
              <TextField id="brand" name="brand" label="Brand" fullWidth value={brand} onChange={(e)=>setBrand(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Diameter</InputLabel>
                <Input id="diameter" name="diameter" type='number' value={diameter} onChange={(e)=>setDiameter(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      cm
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Debit</InputLabel>
                <Input id="debit" name="debit" type='number' value={debit} onChange={(e)=>setDebit(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      l/m
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Qty/Day</InputLabel>
                <Input id="qty_pd" name="qty_pd" type='number' value={qty_pd} onChange={(e)=>setQty_pd(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      Liter
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField id="nb_pd" name="nb_pd" label="Times/Day" type='number' fullWidth value={nb_pd} onChange={(e)=>setNb_pd(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Hose</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Hose Diameter</InputLabel>
                <Input id="h_diameter" name="h_diameter" type='number' value={h_diameter} onChange={(e)=>setH_diameter(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      cm
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField id="nbGouteur" name="nbGouteur" label="Nb Gouteurs" type='number' fullWidth value={nbGouteur} onChange={(e)=>setNbGouteur(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Debit / Gouteur</InputLabel>
                <Input id="debit_pg" name="debit_pg" type='number' value={debit_pg} onChange={(e)=>setDebit_pg(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      l/m
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={isActive} onChange={() => setIsActive(!isActive)} name="isActive" color="primary" />}
                  label="Active"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              type="submit"
            >
              Save
            </Button>
            <Button variant="contained" onClick={() => showIt(false)} style={{ marginRight: 20 }}>
              Cancel
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  )
}

export default EditVanne;