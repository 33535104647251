import { Button, FormControlLabel, FormGroup, Grid, MenuItem, Paper, Switch, TextField, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { addUser, editUser } from '../../../redux/actions/userActions';
import useStyles from './style';

const EditUser = ({showIt, user, setAlert}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [fname, setFName] = useState(user.firstname);
  const [lname, setLName] = useState(user.lastname);
  const [username, setUsername] = useState(user.username);
  const [country, setCountry] = useState(user.country);
  const [state, setState] = useState(user.state);
  const [city, setCity] = useState(user.city);
  const [address, setAddress] = useState(user.address);
  const [phone, setPhone] = useState(user.phone);
  const [postcode, setPostcode] = useState(user.postcode);
  const [role, setRole] = useState(user.roles[0]);
  const [isActive, setIsActive] = useState(user.isActive);
  const [selectedFile, setSelectedFile] = useState(null);

  const roles = [
    {value: 'farmer', label: 'Farmer'},
    {value: 'admin', label: 'Admin'}
  ];
  
  const handleRole = (e) => {
    setRole(e.target.value);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  }

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);

    fetch(`${process.env.REACT_APP_BASE_URL}/users/upload/${user._id}`, {
      mode: 'no-cors',
      method: 'POST',
      body: formData
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const userToUpdate = {
      username: username,
      firstname: fname,
      lastname: lname,
      phone: phone,
      country: country,
      state: state,
      city: city,
      address: address,
      postcode: postcode,
      roles: [role],
      isActive: isActive
    };
    selectedFile && handleFileUpload();
    dispatch(editUser(user._id, userToUpdate));
    setAlert({
      isOpen: true,
      type: 'success',
      message: 'User updated successfully !'
    })
    showIt(false);
  }

  return (
    <div>
        <Paper style={{padding: 30}}>
          <Typography variant="h5" color="primary" style={{marginBottom: 30}} >
            Edit User " {user.firstname} "
          </Typography>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField id="firstname" name="firstname" label="First Name" fullWidth value={fname} onChange={(e)=>setFName(e.target.value)} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField id="lastname" name="lastname" label="Last Name" fullWidth value={lname} onChange={(e)=>setLName(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField id="email" name="email" label="Email Address" fullWidth disabled value={user.email} />
              </Grid>
              <Grid item xs={12}>
                <TextField id="username" name="username" label="Username" fullWidth value={username} onChange={(e)=>setUsername(e.target.value)} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField id="country" name="country" label="Country" fullWidth value={country} onChange={(e)=>setCountry(e.target.value)} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField id="state" name="state" label="State" fullWidth value={state} onChange={(e)=>setState(e.target.value)} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField id="city" name="city" label="City" fullWidth value={city} onChange={(e)=>setCity(e.target.value)} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField id="address" name="address" label="Address" fullWidth value={address} onChange={(e)=>setAddress(e.target.value)} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField id="phone" name="phone" label="Phone" fullWidth value={phone} onChange={(e)=>setPhone(e.target.value)} />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField id="postcode" name="postcode" label="Post Code" fullWidth value={postcode} onChange={(e)=>setPostcode(e.target.value)} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="role"
                  name='role'
                  select
                  label="Role"
                  value={role}
                  onChange={handleRole}
                  fullWidth
                >
                  {roles.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<PhotoCameraIcon />}
                >
                  Edit Image
                  <input
                    type="file"
                    name='file'
                    onChange={handleFileChange}
                    hidden
                  />
                </Button>
                {selectedFile && (<p>{selectedFile.name}</p>)}
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup row style={{}}>
                  <FormControlLabel
                    control={<Switch checked={isActive} onChange={()=>setIsActive(!isActive)} name="isActive" color="primary" />}
                    label="Active"
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <div className={classes.formActions}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Save
              </Button>
              <Button variant="contained" onClick={()=>showIt(false)} style={{marginRight: 20}}>
                Cancel
              </Button>
            </div>
          </form>
        </Paper>
    </div>
  )
}

export default EditUser;