import innoApi from '../../apis/innoApi';
import {ActionTypes} from '../constants/action-types';

export const login = (user) => {
    return async (dispatch) => {
        const response = await innoApi.post('auth/login', user);
        dispatch({type: ActionTypes.LOGIN, payload: response.data});
    }
}

export const logout = () => {
    return async (dispatch) => {
        dispatch({type: ActionTypes.LOGOUT});
    }
}

export const register = (user) => {
    return async (dispatch) => {
        const response = await innoApi.post('users', user);
        dispatch({type: ActionTypes.REGISTER, payload: response.data});
    }
}

export const fetchUsers = () => {
    return async (dispatch) => {
        const response = await innoApi.get('users');
        dispatch({type: ActionTypes.FETCH_USERS, payload: response});
    }
}

export const activateUser = (id, status) => {
    return async (dispatch) => {
        const response = await innoApi.put(`users/activate/${id}/${status}`);
        dispatch({type: ActionTypes.ACTIVATE_USER, payload: response});
    }
}

export const addUser = (user) => {
    return async (dispatch) => {
        const response = await innoApi.post('users/', user);
        dispatch({type: ActionTypes.ADD_USER, payload: response});
    }
}

export const editUser = (id, user) => {
    return async (dispatch) => {
        const response = await innoApi.put(`users/${id}`, user);
        dispatch({type: ActionTypes.EDIT_USER, payload: response});
    }
}

export const deleteUser = (id) => {
    return async (dispatch) => {
        const response = await innoApi.delete(`users/${id}`);
        dispatch({type: ActionTypes.DELETE_USER, payload: response});
    }
}
