import { Button, FormControlLabel, FormGroup, 
        Grid, Paper, Switch, TextField, Typography 
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './style';
import Select from 'react-select';
import 'leaflet-draw/dist/leaflet.draw.css'
import { fetchZones } from '../../../redux/actions/zoneActions';
import { addLora } from '../../../redux/actions/loraActions'
import { NewMap } from './mapNew';
import { fetchVannesByZone } from '../../../redux/actions/vanneActions';
import { fetchSensorsByZone } from '../../../redux/actions/sensorActions';

const NewLora = ({ showIt, setAlert }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [markerPos, setMarkerPos] = useState();
  const [isActive, setIsActive] = useState(true);
  const [type, setType] = useState({ value: 'node', label: 'Node' });
  const typeOptions = [
    { value: 'node', label: 'Node' },
    { value: 'gateway', label: 'Gateway' }
  ]
  const [device, setDevice] = useState({ value: 'sensor', label: 'Sensors' });
  const deviceOptions = [
    { value: 'sensor', label: 'Sensors' },
    { value: 'valve', label: 'Vannes' },
    { value: 'pump', label: 'Pump' },
    { value: 'gwy', label: 'Gateway' }
  ]
  const zones = useSelector(state => state.zones.zones.data)
  const [selectedZone, setSelectedZone] = useState();
  useEffect(() => {
    dispatch(fetchZones());
  }, [zones]);

  const sensors = useSelector(state => state.sensors.sensors.data);
  const vannes = useSelector(state => state.vannes.vannes.data);
  useEffect(()=>{},[sensors, vannes])

  const zoneOptions = [];
  zones && zones.map((zone) => {
    let zoneLabel = zone.title + ' ('+ zone.field.title + ' - ' + zone.field.user.firstname + ')'
    let option = { value: zone, label: zoneLabel };
    zoneOptions.push(option);
  });

  useEffect(()=>{
    if(selectedZone){
      device.value==='sensors' 
      ? dispatch(fetchSensorsByZone(selectedZone.value._id))
      : dispatch(fetchVannesByZone(selectedZone.value._id))
    }
  },[selectedZone, device])


  const handleSubmit = (e) => {
    e.preventDefault();
    const boxToAdd = {
      title: e.target.title.value,
      zone: selectedZone.value,
      zoneId: selectedZone.value?._id,
      field: selectedZone.value?.field._id,
      user: selectedZone.value?.field.user._id,
      macAddr: e.target.macAddr.value,
      type: type.value,
      device: device.value,
      position: {type: 'Point', coordinates: markerPos},
      isActive: isActive
    }
    dispatch(addLora(boxToAdd))
    //dispatch(addField(fieldToAdd));
    showIt(false);
    setAlert({
      isOpen: true,
      type: 'success',
      message: 'New field added successfully !'
    })
  }

  return (
    <div>
      <Paper style={{ padding: 30 }}>
        <Typography variant="h5" color="primary" style={{ marginBottom: 30 }} >
          New LoRa Box
        </Typography>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField id="title" name="title" label="Box Title" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Select
                className={classes.select}
                placeholder="Select Zone"
                onChange={setSelectedZone}
                options={zoneOptions}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField id="macAddr" name="macAddr" label="MAC Address" fullWidth inputProps={{maxLength:2}} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                className={classes.selecttype}
                placeholder={'Type'}
                defaultValue={type}
                onChange={setType}
                options={typeOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                className={classes.selecttype}
                placeholder={'Device'}
                defaultValue={device}
                onChange={setDevice}
                options={deviceOptions}
              />
            </Grid>
            {/* <Grid item xs={12}>
            {selectedZone && device.value==='sensor' && sensors && sensors.map(sensor => (
              <Typography variant="h6">{sensor.title}</Typography>
            ))}
            </Grid>
            <Grid item xs={12}>
            {selectedZone && device.value==='vanne' && vannes && vannes.map(vanne => (
              <Typography variant="h6">{vanne.title}</Typography>
            ))}
            </Grid> */}
            {selectedZone && (
              <Grid item xs={12}>
                <NewMap field={selectedZone.value.field} setMarkerPos={setMarkerPos} markerPos={null} />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={isActive} onChange={() => setIsActive(!isActive)} name="isActive" color="primary" />}
                  label="Active"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              type="submit"
            >
              Save
            </Button>
            <Button variant="contained" onClick={() => showIt(false)} style={{ marginRight: 20 }}>
              Cancel
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  )
}

export default NewLora;