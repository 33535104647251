import { Button, FormControlLabel, FormGroup, Grid, MenuItem, Paper, Switch, TextField, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { addUser } from '../../../redux/actions/userActions';
import useStyles from './style';

const NewUser = ({showIt}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(true);

  const roles = [
    {value: 'farmer', label: 'Farmer'},
    {value: 'admin', label: 'Admin'}
  ];
  const [role, setRole] = useState('farmer');
  const handleRole = (e) => {
    setRole(e.target.value);
  };

  const cleanUp = (e) => {
    e.target.firstname.value = null;
    e.target.lastname.value = null;
    e.target.email.value = null;
    //e.target.password.value = null;
    e.target.phone.value = null;
    e.target.country.value = null;
    e.target.state.value = null;
    e.target.city.value = null;
    e.target.address.value = null;
    e.target.postcode.value = null;
    setRole('farmer');
    setIsActive(true);
    showIt(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const username = e.target.firstname.value + '.' + e.target.lastname.value;
    const userToAdd = {
      username: username,
      firstname: e.target.firstname.value,
      lastname: e.target.lastname.value,
      email: e.target.email.value,
      //password: e.target.password.value,
      phone: e.target.phone.value,
      country: e.target.country.value,
      state: e.target.state.value,
      city: e.target.city.value,
      address: e.target.address.value,
      postcode: e.target.postcode.value,
      roles: [e.target.role.value],
      isActive: isActive
    };
    dispatch(addUser(userToAdd));
    cleanUp(e);
  }

  return (
    <div>
        <Paper style={{padding: 30}}>
          <Typography variant="h5" color="primary" style={{marginBottom: 30}} >
            New User
          </Typography>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField id="firstname" name="firstname" label="First Name" fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField id="lastname" name="lastname" label="Last Name" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField id="email" name="email" label="Email Address" fullWidth />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField id="password" name="password" label="Password" type="password" fullWidth />
              </Grid> */}
              <Grid item xs={12} md={4}>
                <TextField id="country" name="country" label="Country" fullWidth />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField id="state" name="state" label="State" fullWidth />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField id="city" name="city" label="City" fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField id="address" name="address" label="Address" fullWidth />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField id="phone" name="phone" label="Phone" fullWidth />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField id="postcode" name="postcode" label="Post Code" fullWidth />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="role"
                  name='role'
                  select
                  label="Role"
                  value={role}
                  onChange={handleRole}
                  fullWidth
                >
                  {roles.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormGroup row style={{}}>
                  <FormControlLabel
                    control={<Switch checked={isActive} onChange={()=>setIsActive(!isActive)} name="isActive" color="primary" />}
                    label="Active"
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <div className={classes.formActions}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Save
              </Button>
              <Button variant="contained" onClick={()=>showIt(false)} style={{marginRight: 20}}>
                Cancel
              </Button>
            </div>
          </form>
        </Paper>
    </div>
  )
}

export default NewUser;