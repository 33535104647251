import React from 'react'

import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const Map = ({loras}) => {

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  const myLatLng = [36.4, 9.835];
  const capitalize = (word) => (word.charAt(0).toUpperCase()+word.slice(1))

  return (
    <MapContainer
      center={myLatLng}
      zoom={8.5}
      style={{width: '100%', height: 700, borderRadius: 5}}
    >
      <TileLayer
        url='https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=C3lguWuPKbIT8I8tTO7t'
        attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
      />
      {loras && loras.length>0 && loras.map((lora)=> (
        <Marker position={lora.position.coordinates} key={lora._id}>
          <Popup>
            <b>{lora.title}</b><br/>
            {lora.zone.title} ({capitalize(lora.device)})<br/>
            {lora.zone.field.title} <br/>
            {lora.zone.field.user.firstname} {lora.zone.field.user.lastname}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  )
}
export default Map;