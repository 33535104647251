import { ActionTypes } from "../constants/action-types"

const initialState = {
    loras: []
}

export const lorasReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_LORAS:
      return { ...state, loras: payload };
    case ActionTypes.FETCH_LORAS_BY_ZONE:
      return { ...state, loras: payload };
    case ActionTypes.FETCH_LORAS_BY_FIELD:
      return { ...state, loras: payload };
    default:
      return state;
  }
};

export const loraReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ActionTypes.EDIT_LORA:
      return {...state, ...payload};
    case ActionTypes.DELETE_LORA:
      return {...state, ...payload};
    default:
      return state;
  }
};