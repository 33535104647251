import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TerrainIcon from '@material-ui/icons/Terrain';
import SpeedIcon from '@material-ui/icons/Speed';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import RouterIcon from '@material-ui/icons/Router';
import GroupIcon from '@material-ui/icons/Group';
import useStyles from './style';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Router from './routes';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../../redux/actions/userActions';
import logo from '../../global/img/Logo_main_white.png'
import { Avatar } from '@material-ui/core';

const AdminNavigation = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user,setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.setItem('user', null);
    handleClose();
    navigate('/login');
  } 

  useEffect(()=>{
    if(JSON.parse(localStorage.getItem('user')) && typeof(JSON.parse(localStorage.getItem('user'))) !== 'undefined')
      {
        setUser(JSON.parse(localStorage.getItem('user')).user);
        JSON.parse(localStorage.getItem('user')).user.roles[0]==='admin' ? navigate('/admin') : navigate('/');
      } else {
        navigate('/login')
      }
  },[]);

  document.body.style.backgroundColor = "#F5F5F5";

  return (
    user && (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {window.innerWidth > 600 &&
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
          }
          <Link className={classes.title} to={'/'}>
            <img src={logo} style={{width: 150}}/>
          </Link>
          <div className={classes.menuRight}>
            {user.image ? (
              <Avatar 
                alt={user.firstname} 
                src={`${process.env.REACT_APP_BASE_URL}/users/image/${user.image}`} 
                onClick={handleClick}
                style={{width: 30, height: 30, cursor: 'pointer'}}
              />
            ):(
              <AccountCircleIcon color='white' onClick={handleClick} style={{cursor: 'pointer'}}/>
            )}
            {/* <AccountCircleIcon color='white' onClick={handleClick} style={{cursor: 'pointer'}}/> */}
            
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>My Account</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            <Typography variant="h6" noWrap className={classes.welcome}>
              Welcome {user.firstname}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <List>
          <Link className={classes.link} to='users'>
            <ListItem button key={1}>
              <ListItemIcon><GroupIcon color='primary' /></ListItemIcon>
              <ListItemText primary='Users' />
            </ListItem>
          </Link>
          <Link className={classes.link} to='fields'>
            <ListItem button key={2}>
              <ListItemIcon><TerrainIcon color='primary' /></ListItemIcon>
              <ListItemText primary='Fields' />
            </ListItem>
          </Link>
          <Link className={classes.link} to='zones'>
            <ListItem button key={3}>
              <ListItemIcon><DashboardIcon color='primary' /></ListItemIcon>
              <ListItemText primary='Parcels' />
            </ListItem>
          </Link>
          <Link className={classes.link} to='loras'>
            <ListItem button key={4}>
              <ListItemIcon><RouterIcon color='primary' /></ListItemIcon>
              <ListItemText primary='Lora Boxes' />
            </ListItem>
          </Link>
          <Link className={classes.link} to='vannes'>
            <ListItem button key={4}>
              <ListItemIcon><InvertColorsIcon color='primary' /></ListItemIcon>
              <ListItemText primary='Vannes' />
            </ListItem>
          </Link>
          <Link className={classes.link} to='sensors'>
            <ListItem button key={5}>
              <ListItemIcon><SpeedIcon color='primary' /></ListItemIcon>
              <ListItemText primary='Sensors' />
            </ListItem>
          </Link>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Router/>
      </main>
    </div>
    )
  );
}

export default AdminNavigation;