import { Button, FormControl, FormControlLabel, FormGroup, 
        Grid, Input, InputAdornment, InputLabel, Paper, 
        Switch, TextField, Typography 
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from '../../../redux/actions/userActions';
import { addField } from '../../../redux/actions/fieldActions';
import useStyles from './style';
import Select from 'react-select';
import { MapContainer, TileLayer, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css'

const NewField = ({ showIt, setAlert }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(true);
  const [selectedUser, setSelectedUser] = useState(JSON.parse(localStorage.getItem('user')).user);
  const [openTranslation, setOpenTranslation] = useState(false);
  const users = useSelector(state => state.users.users.data);
  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const userOptions = [];
  users && users.map((user) => {
    let userLabel = user.firstname + ' ' + user.lastname;
    let option = { value: user, label: userLabel };
    userOptions.push(option);
  });

  const [mapLayers, setMapLayers] = useState([]);
  const [geoLimits, setGeoLimits] = useState([]);

  const handleAddLimits = (latlngs) => {
    latlngs.map((latlng) => {
      setGeoLimits((geoLimits) => [
        ...geoLimits,
        {"coordinates": [latlng.lat, latlng.lng]}
      ])
    });
  }

  const _onCreated = (e) => {
    const {layerType, layer} = e;
    if(layerType === 'polygon') {
      const { _leaflet_id} = layer;
      setMapLayers((layers) => [ 
        ...layers, 
        { id: _leaflet_id, latlngs: layer.getLatLngs()[0] } 
      ])
    }
  }
  const _onEdited = (e) => {
    const { layers: { _layers }} = e;
    Object.values(_layers).map(({_leaflet_id, editing}) => {
      setMapLayers((layers) => 
        layers.map((layer) => layer.id === _leaflet_id
          ? { ...layer, latlngs: { ...editing.latlngs[0] } }
          : layer
        )
      );
    })
  }
  const _onDeleted = (e) => {
    const { layers: { _layers }} = e;
    Object.values(_layers).map(({_leaflet_id}) => {
      setMapLayers(layers => layers.filter(layer => layer.id !== _leaflet_id));
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const fieldToAdd = {
      title: e.target.title.value,
      title_tr: {
        en: e.target.title.value,
        fr: e.target.title_fr?.value,
        ar: e.target.title_ar?.value
      },
      user: selectedUser.value,
      description: e.target.description.value,
      description_tr: {
        en: e.target.description.value,
        fr: e.target.description_fr?.value,
        ar: e.target.description_ar?.value
      } ,
      country: e.target.country.value,
      state: e.target.state.value,
      city: e.target.city.value,
      address: e.target.address.value,
      country_tr: {
        en: e.target.country.value,
        fr: e.target.country_fr?.value,
        ar: e.target.country_ar?.value
      },
      city_tr: {
        en: e.target.city.value,
        fr: e.target.city_fr?.value,
        ar: e.target.city_ar?.value
      },
      state_tr: {
        en: e.target.state.value,
        fr: e.target.state_fr?.value,
        ar: e.target.state_ar?.value
      } ,
      address_tr: {
        en: e.target.address.value,
        fr: e.target.address_fr?.value,
        ar: e.target.address_ar?.value
      },
      area: e.target.area.value,
      areaUnit: 'Ha',
      geoLimits: geoLimits,
      isActive: isActive
    }
    dispatch(addField(fieldToAdd));
    showIt(false);
    setAlert({
      isOpen: true,
      type: 'success',
      message: 'New field added successfully !'
    })
  }

  return (
    <div>
      <Paper style={{ padding: 30 }}>
        <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
          <Typography variant="h5" color="primary" style={{ marginBottom: 30 }} >
            New Field
          </Typography>
          <Button onClick={()=>setOpenTranslation(!openTranslation)}><GTranslateIcon color='primary' /></Button>
        </div>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField name="title" label="Field Title" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Owner</InputLabel>
                <Select

                  onChange={setSelectedUser}
                  options={userOptions}
                />
              </FormControl>
            </Grid>
            {openTranslation && (<>
            <Grid item xs={12} md={6}>
              <TextField name="title_fr" label="Title (French)" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="title_ar" label="Title (Arabic)" fullWidth />
            </Grid>
            </>)}
            <Grid item xs={12}>
              <TextField name="description" label="Description" fullWidth multiline />
            </Grid>
            {openTranslation && (<>
            <Grid item xs={12}>
              <TextField name="description_fr" label="Description (French)" fullWidth multiline />
            </Grid>
            <Grid item xs={12}>
              <TextField name="description_ar" label="Description (Arabic)" fullWidth multiline />
            </Grid>
            </>)}
            <Grid item xs={12} md={4}>
              <TextField id="country" name="country" label="Country" fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField name="state" label="State" fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField name="city" label="City" fullWidth />
            </Grid>
            {openTranslation && (<>
            <Grid item xs={12} md={4}>
              <TextField name="country_fr" label="Country (French)" fullWidth defaultValue='' />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField name="state_fr" label="State (French)" fullWidth defaultValue=''/>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField name="city_fr" label="City (French)" fullWidth defaultValue=''/>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField name="country_ar" label="Country (Arabic)" fullWidth defaultValue=''/>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField name="state_ar" label="State (Arabic)" fullWidth defaultValue=''/>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField name="city_ar" label="City (Arabic)" fullWidth defaultValue=''/>
            </Grid>
            </>)}
            <Grid item xs={12} md={6}>
              <TextField name="address" label="Address" fullWidth />
            </Grid>
            {openTranslation && (<>
            <Grid item xs={12} md={6}>
              <TextField name="address_fr" label="Address (French)" fullWidth defaultValue=''/>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="address_ar" label="Address (Arabic)" fullWidth defaultValue=''/>
            </Grid>
            </>)}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Area</InputLabel>
                <Input id="area" name="area" type='number'
                  endAdornment={
                    <InputAdornment position="end">
                      Ha
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <MapContainer
                center={[35.826, 9.657]}
                zoom={7}
                style={{width: '100%', height: 400, borderRadius: 5}}
              >
                <TileLayer
                  url='https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=C3lguWuPKbIT8I8tTO7t'
                  attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                />
                <FeatureGroup>
                  <EditControl 
                    position='topright'
                    onCreated={_onCreated}
                    onEdited={_onEdited}
                    onDeleted={_onDeleted}
                    draw={{ rectangle: false, polyline: false, circle: false, marker: false, circlemarker: false}}
                  />
                </FeatureGroup>
              </MapContainer>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={()=> mapLayers.length>0 && handleAddLimits(mapLayers[0].latlngs)} style={{ marginRight: 20 }}>
                Validate Location
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={isActive} onChange={() => setIsActive(!isActive)} name="isActive" color="primary" />}
                  label="Active"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              type="submit"
            >
              Save
            </Button>
            <Button variant="contained" onClick={() => showIt(false)} style={{ marginRight: 20 }}>
              Cancel
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  )
}

export default NewField;