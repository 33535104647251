import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { MapContainer, Marker, Polygon, TileLayer, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

export const NewMap = ({field, setMarkerPos, markerPos}) => {
  const classes = useStyles();
  const [center, setCenter] = useState(null);
  const [coordinates, setCoordinates] = useState([]);

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
  });
  L.Marker.prototype.options.icon = DefaultIcon;

  useEffect(()=>{
    setCoordinates([])
    setCenter(null)
    field.geoLimits && field.geoLimits.length > 0 && setCenter([field.geoLimits[0].coordinates[0], field.geoLimits[0].coordinates[1]]);
    field.geoLimits && field.geoLimits.length > 0 && field.geoLimits.map((geoPoint) => {
        setCoordinates(coordinates => [...coordinates, geoPoint.coordinates])
    })
  },[])
  useEffect(()=>{},[coordinates])
  useEffect(()=>{},[center])

  const AddMarker = () => {
    const map = useMapEvents({
      dblclick:(e)=>{
        const { lat, lng } = e.latlng;
        setMarkerPos([lat, lng])
        L.marker([lat, lng], {DefaultIcon}).addTo(map);
      },
    })
    return null;
  }
  
  

  return (
    <div>
    {center && coordinates && coordinates.length>0 && (
    <MapContainer
        center={center}
        zoom={18}
        style={{width: '100%', height: 400, borderRadius: 5}}
    >
        <TileLayer
        url='https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=C3lguWuPKbIT8I8tTO7t'
        attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        />
        <AddMarker/>
        {markerPos && (
          <Marker position={markerPos}></Marker>
        )}
        <Polygon
        pathOptions={{
            weight: 2,
            opacity: 1,
            dashArray: 3,
            color: '#105157',
            fillColor: '#F4F4F4',
            fillOpacity: 0.7,
        }}
        positions={coordinates}
        />
    </MapContainer>
    )}
    </div>
  )
}
