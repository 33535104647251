import { Button, FormControl, FormControlLabel, FormGroup, Grid, Input, InputAdornment, InputLabel, Paper, Switch, TextField, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import useStyles from './style';
import Select from 'react-select';
import { editZone } from '../../../redux/actions/zoneActions';

const EditZone = ({showIt, zone, setAlert}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [title, setTitle] = useState(zone.title);
  const [titleFr, setTitleFr] = useState(zone.title_tr ? zone.title_tr.fr : null);
  const [titleAr, setTitleAr] = useState(zone.title_tr ? zone.title_tr.ar : null);
  const [field] = useState(zone.field);
  const [description, setDescription] = useState(zone.description);
  const [descriptionFr, setDescriptionFr] = useState(zone.description_tr ? zone.description_tr.fr : null);
  const [descriptionAr, setDescriptionAr] = useState(zone.description_tr ? zone.description_tr.ar : null);
  const [area, setArea] = useState(zone.area);
  const [isActive, setIsActive] = useState(zone.isActive);
  const [selectedMode, setSelectedMode] = useState({ value: zone.irrigMode, label: zone.irrigMode==='man' ? 'Manual' : 'Automatic'});
  
  const [selectedFile, setSelectedFile] = useState(null);
  const [openTranslation, setOpenTranslation] = useState(false);

  const modeOptions = [
    { value: 'man', label: 'Manual'},
    { value: 'auto', label: 'Automatic'},
  ];
  const soilOptions = [
    { value: 'sable', label: 'Sable'},
    { value: 'sable-argileuse', label: 'Sable Agrileuse'},
    { value: 'argile-sableuse', label: 'Argile Sableuse'},
    { value: 'argile', label: 'Argile'},
    { value: 'argile-limoneuse', label: 'Argile Limoneuse'},
    { value: 'limon-agrilo-sableux', label: 'Equilibrée'},
    { value: 'limon-argileux', label: 'Limon Argileux'},
    { value: 'limon', label: 'Limon'},
  ];
  const [selectedSoil, setSelectedSoil] = useState({ value: zone.soilType, label: soilOptions.filter(soil => soil.value===zone.soilType)[0].label});

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  }

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);

    fetch(`${process.env.REACT_APP_BASE_URL}/zones/upload/${zone._id}`, {
      mode: 'no-cors',
      method: 'POST',
      body: formData
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const zoneToUpdate = {
      title: e.target.title.value,
      title_tr: {
        en: e.target.title.value,
        fr: e.target.title_fr?.value,
        ar: e.target.title_ar?.value
      },
      description: e.target.description.value,
      description_tr: {
        en: e.target.description.value,
        fr: e.target.description_fr?.value,
        ar: e.target.description_ar?.value
      },
      soilType: selectedSoil.value,
      irrigMode: selectedMode.value,
      area: e.target.area.value,
      areaUnit: 'm2',
      isActive: isActive
    }
    selectedFile && handleFileUpload();
    dispatch(editZone(zone._id, zoneToUpdate));
    showIt(false);
    setAlert({
      isOpen: true,
      type: 'success',
      message: 'Zone edited successfully !'
    })
  }

  return (
    <div>
      <Paper style={{ padding: 30 }}>
        <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
          <Typography variant="h5" color="primary" style={{ marginBottom: 30 }} >
            Edit Zone
          </Typography>
          <Button onClick={()=>setOpenTranslation(!openTranslation)}><GTranslateIcon color='primary' /></Button>
        </div>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField id="title" name="title" label="Zone Title" fullWidth value={title} onChange={(e)=>setTitle(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField id="field" name="field" label="Field" fullWidth disabled value={field.title + ' | ' + field.user.firstname + ' ' + field.user.lastname} />
            </Grid>
            {openTranslation && (<>
              <Grid item xs={12} md={6}>
                <TextField name="title_fr" label="Zone Title (FR)" fullWidth value={titleFr} onChange={(e)=>setTitleFr(e.target.value)} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField name="title_ar" label="Zone Title (AR)" fullWidth value={titleAr} onChange={(e)=>setTitleAr(e.target.value)} />
              </Grid>
            </>)}
            <Grid item xs={12}>
              <TextField id="description" name="description" label="Description" fullWidth multiline value={description} onChange={(e)=>setDescription(e.target.value)} />
            </Grid>
            {openTranslation && (<>
              <Grid item xs={12}>
                <TextField name="description_fr" label="Description (FR)" fullWidth multiline value={descriptionFr} onChange={(e)=>setDescriptionFr(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField name="description_ar" label="Description (AR)" fullWidth multiline value={descriptionAr} onChange={(e)=>setDescriptionAr(e.target.value)} />
              </Grid>
            </>)}
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl} fullWidth>
              <Typography color='textSecondary'>Soil Type</Typography>
                <Select
                  defaultValue={selectedSoil}
                  onChange={setSelectedSoil}
                  options={soilOptions}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl} fullWidth>
              <Typography color='textSecondary'>Irrigation Mode</Typography>
                <Select
                  defaultValue={selectedMode}
                  onChange={setSelectedMode}
                  options={modeOptions}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Area</InputLabel>
                <Input id="area" name="area" type='number' value={area} onChange={(e)=>setArea(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      Ha
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<PhotoCameraIcon />}
                >
                  Edit Image
                  <input
                    type="file"
                    name='file'
                    onChange={handleFileChange}
                    hidden
                  />
                </Button>
                {selectedFile && (<p>{selectedFile.name}</p>)}
              </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={isActive} onChange={() => setIsActive(!isActive)} name="isActive" color="primary" />}
                  label="Active"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              type="submit"
            >
              Save
            </Button>
            <Button variant="contained" onClick={() => showIt(false)} style={{ marginRight: 20 }}>
              Cancel
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  )
}

export default EditZone;