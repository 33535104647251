import { Button, FormControl, FormControlLabel, FormGroup, Grid, Input, InputAdornment, InputLabel, Paper, Switch, TextField, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { editField } from '../../../redux/actions/fieldActions';
import useStyles from './style';

const EditField = ({showIt, field, setAlert}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openTranslation, setOpenTranslation] = useState(false);
  const [title, setTitle] = useState(field.title);
  const [titleFr, setTitleFr] = useState(field.title_tr ? field.title_tr.fr : null);
  const [titleAr, setTitleAr] = useState(field.title_tr ? field.title_tr.ar : null);
  const [user] = useState(field.user);
  const [description, setDescription] = useState(field.description);
  const [descriptionFr, setDescriptionFr] = useState(field.description_tr ? field.description_tr.fr : null);
  const [descriptionAr, setDescriptionAr] = useState(field.description_tr ? field.description_tr.ar : null);
  const [country, setCountry] = useState(field.country);
  const [countryFr, setCountryFr] = useState(field.country_tr ? field.country_tr.fr : null);
  const [countryAr, setCountryAr] = useState(field.country_tr ? field.country_tr.ar : null);
  const [state, setState] = useState(field.state);
  const [stateFr, setStateFr] = useState(field.state_tr ? field.state_tr.fr : null);
  const [stateAr, setStateAr] = useState(field.state_tr ? field.state_tr.ar : null);
  const [city, setCity] = useState(field.city);
  const [cityFr, setcityFr] = useState(field.city_tr ? field.city_tr.fr : null);
  const [cityAr, setcityAr] = useState(field.city_tr ? field.city_tr.ar : null);
  const [address, setAddress] = useState(field.address);
  const [addressFr, setaddressFr] = useState(field.address_tr ? field.address_tr.fr : null);
  const [addressAr, setaddressAr] = useState(field.address_tr ? field.address_tr.ar : null);
  const [area, setArea] = useState(field.area);
  const [isActive, setIsActive] = useState(field.isActive);

  const handleSubmit = (e) => {
    e.preventDefault();
    const fieldToUpdate = {
      title: e.target.title.value,
      title_tr: {
        en: e.target.title.value,
        fr: e.target.title_fr?.value,
        ar: e.target.title_ar?.value
      },
      description: e.target.description.value,
      description_tr: {
        en: e.target.description.value,
        fr: e.target.description_fr?.value,
        ar: e.target.description_ar?.value
      },
      country: e.target.country.value,
      state: e.target.state.value,
      city: e.target.city.value,
      address: e.target.address.value,
      country_tr: {
        en: e.target.country.value,
        fr: e.target.country_fr?.value,
        ar: e.target.country_ar?.value
      },
      city_tr: {
        en: e.target.city.value,
        fr: e.target.city_fr?.value,
        ar: e.target.city_ar?.value
      },
      state_tr: {
        en: e.target.state.value,
        fr: e.target.state_fr?.value,
        ar: e.target.state_ar?.value
      } ,
      address_tr: {
        en: e.target.address.value,
        fr: e.target.address_fr?.value,
        ar: e.target.address_ar?.value
      },
      area: e.target.area.value,
      areaUnit: 'Ha',
      isActive: isActive
    }
    dispatch(editField(field._id, fieldToUpdate));
    showIt(false);
    setAlert({
      isOpen: true,
      type: 'success',
      message: 'Field edited successfully !'
    })
  }

  return (
    <div>
      <Paper style={{ padding: 30 }}>
        <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
        <Typography variant="h5" color="primary" style={{ marginBottom: 30 }} >
          Edit Field
        </Typography>
        <Button onClick={()=>setOpenTranslation(!openTranslation)}><GTranslateIcon color='primary' /></Button>
        </div>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField name="title" label="Field Title" fullWidth value={title} onChange={(e)=>setTitle(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="user" label="User" fullWidth disabled value={user.firstname + ' ' + user.lastname} />
            </Grid>
            {openTranslation && (<>
            <Grid item xs={12} md={6}>
              <TextField name="title_fr" label="Title (French)" fullWidth value={titleFr} onChange={(e)=>setTitleFr(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="title_ar" label="Title (Arabic)" fullWidth value={titleAr} onChange={(e)=>setTitleAr(e.target.value)} />
            </Grid>
            </>)}
            <Grid item xs={12}>
              <TextField name="description" label="Description" fullWidth multiline value={description} onChange={(e)=>setDescription(e.target.value)} />
            </Grid>
            {openTranslation && (<>
            <Grid item xs={12}>
              <TextField name="description_fr" label="Description (French)" fullWidth multiline value={descriptionFr} onChange={(e)=>setDescriptionFr(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="description_ar" label="Description (Arabic)" fullWidth multiline value={descriptionAr} onChange={(e)=>setDescriptionAr(e.target.value)} />
            </Grid>
            </>)}
            <Grid item xs={12} md={4}>
              <TextField name="country" label="Country" fullWidth value={country} onChange={(e)=>setCountry(e.target.value)}/>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField name="state" label="State" fullWidth value={state} onChange={(e)=>setState(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField name="city" label="City" fullWidth value={city} onChange={(e)=>setCity(e.target.value)} />
            </Grid>
            {openTranslation && (<>
            <Grid item xs={12} md={4}>
              <TextField name="country_fr" label="Country (French)" fullWidth value={countryFr} onChange={(e)=>setCountryFr(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField name="state_fr" label="State (French)" fullWidth value={stateFr} onChange={(e)=>setStateFr(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField name="city_fr" label="City (French)" fullWidth value={cityFr} onChange={(e)=>setcityFr(e.target.value)}/>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField name="country_ar" label="Country (Arabic)" fullWidth value={countryAr} onChange={(e)=>setCountryAr(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField name="state_ar" label="State (Arabic)" fullWidth value={stateAr} onChange={(e)=>setStateAr(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField name="city_ar" label="City (Arabic)" fullWidth value={cityAr} onChange={(e)=>setcityAr(e.target.value)}/>
            </Grid>
            </>)}
            <Grid item xs={12} md={6}>
              <TextField name="address" label="Address" fullWidth value={address} onChange={(e)=>setAddress(e.target.value)} />
            </Grid>
            {openTranslation && (<>
            <Grid item xs={12} md={6}>
              <TextField name="address_fr" label="Address (French)" fullWidth value={addressFr} onChange={(e)=>setaddressFr(e.target.value)}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="address_ar" label="Address (Arabic)" fullWidth value={addressAr} onChange={(e)=>setaddressAr(e.target.value)}/>
            </Grid>
            </>)}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Area</InputLabel>
                <Input id="area" name="area" type='number' value={area} onChange={(e)=>setArea(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      Ha
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={isActive} onChange={() => setIsActive(!isActive)} name="isActive" color="primary" />}
                  label="Active"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              type="submit"
            >
              Save
            </Button>
            <Button variant="contained" onClick={() => showIt(false)} style={{ marginRight: 20 }}>
              Cancel
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  )
}

export default EditField;