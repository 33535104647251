import React, {useEffect, useState} from 'react';
import { Badge, Button, Modal, Paper, Switch, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Typography, Grid
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, activateUser, deleteUser } from '../../../redux/actions/userActions';
import useStyles from './style';
import NewUser from './newUser';
import EditUser from './editUser';
import { UserDetails } from './details';
import { Alert } from '@material-ui/lab';

const Users = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showNewForm, setShowNewForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const users = useSelector(state => state.users.users.data);
  useEffect(()=>{
    dispatch(fetchUsers());
  },[users]);
  let i = 1;

  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    message: ''
  })

  useEffect(()=>{
    alert.isOpen && setTimeout(() => {
      setAlert({isOpen: false, type: '', message: ''})
    }, "3000")
  })

  const activate = (e) => {
    dispatch(activateUser(e.target.name, e.target.checked));
  }

  const onDeleteClick = (id, firstname) => {
    setOpenModal(true);
    setDeleteId(id);
    setDeleteName(firstname);
  }

  const handleCancel = () => {
    setDeleteId(null);
    setDeleteName(null);
    setOpenModal(false);
  }

  const handleEdit = (user) => {
    setShowEditForm(true);
    setSelectedUser(user);
  }

  const handleDetails = (user) => {
    setShowDetails(true);
    setSelectedUser(user);
  }

  const handleDelete = () => {
    dispatch(deleteUser(deleteId));
    setOpenModal(false);
    setAlert({
      isOpen: true,
      type: 'success',
      message: 'User deleted successfully !'
    })
  }

  const columns = ['N°', 'Name', 'Email', 'Role', 'Address', 'Active', 'Actions'];

  return (
    <div>
      {alert.isOpen && (
        <Alert variant="filled" severity={alert.type} onClose={()=>{setAlert({isOpen: false})}} className={classes.alert}>
          {alert.message}
        </Alert>
      )}
      <div className={classes.between}>
       <Typography variant="h4" color="primary" style={{paddingLeft: 30}}>
          Users
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>setShowNewForm(true)}
          className={classes.newButton}
          startIcon={<AddIcon color='secondary'/>}
        >
          New User
        </Button>
      </div>
      
      <Grid container spacing={3}>
        <Grid item xs={12} lg={(showNewForm || showEditForm || showDetails) ? (7) : (12)}>
          <TableContainer component={Paper} className={classes.paperPad}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column)=>(<TableCell key={column} className={classes.columnTitle}>{column}</TableCell>))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users && users.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell>{i++}</TableCell>
                    <TableCell>{user.firstname} {user.lastname}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <Badge badgeContent={user.roles[0]} color={user.roles[0] === 'admin' ? 'secondary' : 'primary'}/>
                    </TableCell>
                    <TableCell>{user.state}, {user.city}</TableCell>
                    <TableCell>
                      <Switch
                        checked={user.isActive}
                        onClick={activate}
                        color="secondary"
                        name={user._id}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.actionIcons}>
                        <LaunchIcon className={classes.actionIcon} onClick={()=> handleDetails(user)} />
                        <EditIcon className={classes.actionIcon} onClick={()=>handleEdit(user)} />
                        <DeleteIcon className={classes.actionIcon} onClick={()=>onDeleteClick(user._id, user.firstname)} />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {showNewForm && (
        <Grid item xs={12} lg={5}>
          <NewUser showIt={setShowNewForm} setAlert={setAlert} />
        </Grid>
        )}
        {showEditForm && (
        <Grid item xs={12} lg={5}>
          <EditUser showIt={setShowEditForm} user={selectedUser} setAlert={setAlert} />
        </Grid>
        )}
        {showDetails && (
        <Grid item xs={12} lg={5}>
          <UserDetails showIt={setShowDetails} selectedUser={selectedUser}/>
        </Grid>
        )}
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCancel}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalPaper}>
          <Typography variant="h5" color='primary' gutterBottom>
            Delete User
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete <b>{deleteName}</b> ?
          </Typography>
          <br/>
          <div className={classes.flexRight}>
            <Button variant="contained" size='small' className={classes.modalBtn} color="primary" onClick={handleDelete}>
              Delete
            </Button>
            <Button variant="contained" size='small' className={classes.modalBtn} onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default Users;