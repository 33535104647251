import { Button, FormControl, FormControlLabel, FormGroup, 
        Grid, Input, InputAdornment, InputLabel, Paper, 
        Switch, TextField, Typography 
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchFields } from '../../../redux/actions/fieldActions';
import useStyles from './style';
import Select from 'react-select';
import { addZone } from '../../../redux/actions/zoneActions';

const NewZone = ({ showIt, setAlert }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(true);
  const [selectedField, setSelectedField] = useState();
  const [selectedMode, setSelectedMode] = useState({ value: 'man', label: 'Manual'});
  const [selectedSoil, setSelectedSoil] = useState({ value: 'sable', label: 'Sable'});
  const [openTranslation, setOpenTranslation] = useState(false);
  
  const modeOptions = [
    { value: 'man', label: 'Manual'},
    { value: 'auto', label: 'Automatic'},
  ];

  const soilOptions = [
    { value: 'sable', label: 'Sable'},
    { value: 'sable-argileuse', label: 'Sable Agrileuse'},
    { value: 'argile-sableuse', label: 'Argile Sableuse'},
    { value: 'argile', label: 'Argile'},
    { value: 'argile-limoneuse', label: 'Argile Limoneuse'},
    { value: 'limon-agrilo-sableux', label: 'Limon Argilo Sableux'},
    { value: 'limon-argileux', label: 'Limon Argileux'},
    { value: 'limon', label: 'Limon'},
  ];

  const fields = useSelector(state => state.fields.fields.data);
  useEffect(() => {
    dispatch(fetchFields());
  }, [fields]);

  const fieldOptions = [];
  fields && fields.map((field) => {
    let fieldLabel = field.title + ' | ' + field.user.firstname + ' ' + field.user.lastname;
    let option = { value: field, label: fieldLabel };
    fieldOptions.push(option);
  });


  const handleSubmit = (e) => {
    e.preventDefault();
    const zoneToAdd = {
      title: e.target.title.value,
      title_tr: {
        en: e.target.title.value,
        fr: e.target.title_fr?.value,
        ar: e.target.title_ar?.value
      },
      field: selectedField.value,
      user: selectedField.value.user._id,
      description: e.target.description.value,
      description_tr: {
        en: e.target.description.value,
        fr: e.target.description_fr?.value,
        ar: e.target.description_ar?.value
      },
      soilType: selectedSoil.value,
      irrigMode: selectedMode.value,
      area: e.target.area.value,
      areaUnit: 'm2',
      isActive: isActive
    }
    dispatch(addZone(zoneToAdd));
    showIt(false);
    setAlert({
      isOpen: true,
      type: 'success',
      message: 'New zone added successfully !'
    })
  }

  return (
    <div>
      <Paper style={{ padding: 30 }}>
        <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
        <Typography variant="h5" color="primary" style={{ marginBottom: 30 }} >
          New Zone
        </Typography>
        <Button onClick={()=>setOpenTranslation(!openTranslation)}><GTranslateIcon color='primary' /></Button>
        </div>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField id="title" name="title" label="Zone Title" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl} fullWidth>
                <Typography color='textSecondary'>Field</Typography>
                <Select
                  onChange={setSelectedField}
                  options={fieldOptions}
                />
              </FormControl>
            </Grid>
            {openTranslation && (<>
              <Grid item xs={12} md={6}>
                <TextField name="title_fr" label="Zone Title (FR)" fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField name="title_ar" label="Zone Title (AR)" fullWidth />
              </Grid>
            </>)}
            <Grid item xs={12}>
              <TextField id="description" name="description" label="Description" fullWidth multiline />
            </Grid>
            {openTranslation && (<>
              <Grid item xs={6}>
                <TextField name="description_fr" label="Description (FR)" fullWidth multiline />
              </Grid>
              <Grid item xs={6}>
                <TextField name="description_ar" label="Description (AR)" fullWidth multiline />
              </Grid>
            </>)}
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl} fullWidth>
              <Typography color='textSecondary'>Soil Type</Typography>
                <Select
                  defaultValue={selectedSoil}
                  onChange={setSelectedSoil}
                  options={soilOptions}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl} fullWidth>
              <Typography color='textSecondary'>Irrigation Mode</Typography>
                <Select
                  defaultValue={selectedMode}
                  onChange={setSelectedMode}
                  options={modeOptions}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Area</InputLabel>
                <Input id="area" name="area" type='number'
                  endAdornment={
                    <InputAdornment position="end">
                      m²
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={isActive} onChange={() => setIsActive(!isActive)} name="isActive" color="primary" />}
                  label="Active"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              type="submit"
            >
              Save
            </Button>
            <Button variant="contained" onClick={() => showIt(false)} style={{ marginRight: 20 }}>
              Cancel
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  )
}

export default NewZone;