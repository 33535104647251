import React, {useEffect, useState} from 'react';
import { Button, Modal, Paper, Switch, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Typography, Grid, Badge
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './style';
import { deleteSensor, fetchSensors, removeFromLora } from '../../../redux/actions/sensorActions';
import NewSensor from './newSensor';
import EditSensor from './editSensor';

const Sensors = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showNewForm, setShowNewForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState(null);
  const [deleteLora, setDeleteLora] = useState(null);
  const [selectedSensor, setSelectedSensor] = useState(null);

  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    message: ''
  })
  

  const sensors = useSelector(state => state.sensors.sensors.data);
  useEffect(()=>{
    dispatch(fetchSensors());
  },[sensors]);
  let i = 1;

  const onDeleteClick = (id, title, lora) => {
    setOpenModal(true);
    setDeleteId(id);
    setDeleteName(title);
    lora && setDeleteLora(lora);
  }

  const handleCancel = () => {
    setDeleteId(null);
    setDeleteName(null);
    setOpenModal(false);
  }

  const handleEdit = (sensor) => {
    setShowEditForm(true);
    setSelectedSensor(sensor);
  }

  // const handleDetails = (zone) => {
  //   setShowDetails(true);
  //   setSelectedZone(zone);
  // }

  const handleDelete = () => {
    dispatch(deleteSensor(deleteId));
    deleteLora && dispatch(removeFromLora(deleteLora, deleteId))
    setOpenModal(false);
    setAlert({
      isOpen: true,
      type: 'success',
      message: 'Sensor deleted successfully !'
    })
  }

  useEffect(()=>{
    alert.isOpen && setTimeout(() => {
      setAlert({isOpen: false, type: '', message: ''})
    }, "3000")
  })

  const columns = ['N°', 'ID', 'Title', 'Zone of Field', 'Type', 'LoRa', 'Actions'];

  return (
    <div>
      {alert.isOpen && (
        <Alert variant="filled" severity={alert.type} onClose={()=>{setAlert({isOpen: false})}} className={classes.alert}>
          {alert.message}
        </Alert>
      )}
      <div className={classes.between}>
       <Typography variant="h4" color="primary" style={{paddingLeft: 30}}>
          Sensors
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>setShowNewForm(true)}
          className={classes.newButton}
          startIcon={<AddIcon />}
        >
          New Sensor
        </Button>
      </div>
      
      <Grid container spacing={3}>
        <Grid item xs={12} lg={(showNewForm || showEditForm || showDetails) ? (7) : (12)}>
          <TableContainer component={Paper} className={classes.paperPad}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column)=>(<TableCell key={column}>{column}</TableCell>))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sensors && sensors.map((sensor) => (
                  <TableRow key={sensor._id}>
                    <TableCell>{i++}</TableCell>
                    <TableCell>{sensor._id}</TableCell>
                    <TableCell>{sensor.title}</TableCell>
                    <TableCell>{sensor.zone.title} of {sensor.zone.field.title}</TableCell>
                    <TableCell>{sensor.type}</TableCell>
                    <TableCell>{sensor.lorabox?.macAddr}</TableCell>
                    {/* <TableCell>
                      {sensors.values.length>=1 && sensor.values[sensor.values.length -1].value} 
                      {sensors.values.length>=1 && sensor.values[sensor.values.length -1].unity && 
                      sensor.values[sensor.values.length -1].unity}
                    </TableCell> */}
                    <TableCell>
                      <div className={classes.actionIcons}>
                        {/* <LaunchIcon className={classes.actionIcon} onClick={()=> handleDetails(field)} /> */}
                        <EditIcon className={classes.actionIcon} onClick={()=>handleEdit(sensor)} />
                        <DeleteIcon className={classes.actionIcon} onClick={()=>onDeleteClick(sensor._id, sensor.title, sensor.loraboxId)} />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {showNewForm && (
        <Grid item xs={12} lg={5}>
          <NewSensor showIt={setShowNewForm} setAlert={setAlert}/>
        </Grid>
        )}
         
        {showEditForm && (
        <Grid item xs={12} lg={5}>
          <EditSensor showIt={setShowEditForm} sensor={selectedSensor} setAlert={setAlert}/>
        </Grid>
        )}
        {/*
        {showDetails && (
        <Grid item xs={12} lg={5}>
          <UserDetails showIt={setShowDetails} selectedUser={selectedUser}/>
        </Grid>
        )} */}
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCancel}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalPaper}>
          <Typography variant="h5" color='primary' gutterBottom>
            Delete Sensor
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete <b>{deleteName}</b> ?
          </Typography>
          <br/>
          <div className={classes.flexRight}>
            <Button variant="contained" size='small' className={classes.modalBtn} color="primary" onClick={handleDelete}>
              Delete
            </Button>
            <Button variant="contained" size='small' className={classes.modalBtn} onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default Sensors;