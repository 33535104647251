import innoApi from '../../apis/innoApi';
import {ActionTypes} from '../constants/action-types';

export const fetchVannesByZone = (zone) => {
    return async (dispatch) => {
        const response = await innoApi.get(`vannes/zone/${zone}`);
        dispatch({type: ActionTypes.FETCH_VANNES_BY_ZONE, payload: response});
    }
}

export const openCloseVanne = (id, status) => {
    return async (dispatch) => {
        const response = await innoApi.put(`vannes/status/${id}/${status}`);
        dispatch({type: ActionTypes.OPEN_CLOSE_VANNE, payload: response});
    }
}

export const fetchVannes = () => {
    return async (dispatch) => {
        const response = await innoApi.get('vannes');
        dispatch({type: ActionTypes.FETCH_VANNES, payload: response});
    }
}

export const addVanne = (vanne) => {
    return async (dispatch) => {
        const response = await innoApi.post('vannes/', vanne);
        dispatch({type: ActionTypes.ADD_VANNE, payload: response});
    }
}

export const activateVanne = (id, status) => {
    return async (dispatch) => {
        const response = await innoApi.put(`vannes/activate/${id}/${status}`);
        dispatch({type: ActionTypes.ACTIVATE_VANNE, payload: response});
    }
}

export const editVanne = (id, vanne) => {
    return async (dispatch) => {
        const response = await innoApi.put(`vannes/${id}`, vanne);
        dispatch({type: ActionTypes.EDIT_VANNE, payload: response});
    }
}

export const deleteVanne = (id) => {
    return async (dispatch) => {
        const response = await innoApi.delete(`vannes/${id}`);
        dispatch({type: ActionTypes.DELETE_VANNE, payload: response});
    }
}

export const addPeriods = (id, periods) => {
    return async () => {
        const response = await innoApi.put(`vannes/periods/${id}`, periods);
    }
}

export const deletePeriod = (vanne, period) => {
    return async (dispatch) => {
        const response = await innoApi.put(`vannes/periods/delete/${vanne}/${period}`);
        dispatch({type: ActionTypes.DELETE_PERIOD, payload: response});
    }
}

export const addToLora = (lora, vanneId) => {
    return async () => { await innoApi.put(`lora/add/vanne/${lora}/${vanneId}`) }
}

export const removeFromLora = (lora, vanneId) => {
    return async () => { await innoApi.put(`lora/remove/vanne/${lora}/${vanneId}`) }
}