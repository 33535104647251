import { Button, FormControl, Grid,  Paper, TextField, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './style';
import Select from 'react-select';
import { fetchZones } from '../../../redux/actions/zoneActions';
import { addToLora, editSensor, removeFromLora } from '../../../redux/actions/sensorActions';
import { fetchLorasByZone } from '../../../redux/actions/loraActions';

const EditSensor = ({showIt, sensor, setAlert}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const zoneLabel = sensor.zone.title + ' | ' + sensor.zone.field.title
  const loraLabel = sensor.lorabox ? (sensor.lorabox?.title + ' (' + sensor.lorabox?.device + ')') : 'choose LoraBox'
  const [openTranslation, setOpenTranslation] = useState(false);
  const [title, setTitle] = useState(sensor.title);
  const [titleFr, setTitleFr] = useState(sensor.title_tr ? sensor.title_tr.fr : null);
  const [titleAr, setTitleAr] = useState(sensor.title_tr ? sensor.title_tr.ar : null);
  const [selectedZone, setSelectedZone] = useState({ value: sensor.zone, label: zoneLabel});
  const [selectedLora, setSelectedLora] = useState({ value: sensor.lorabox, label: loraLabel});
  const [description, setDescription] = useState(sensor.description);
  const [descriptionFr, setDescriptionFr] = useState(sensor.description_tr ? sensor.description_tr.fr : null);
  const [descriptionAr, setDescriptionAr] = useState(sensor.description_tr ? sensor.description_tr.ar : null);
  const [reference, setReference] = useState(sensor.reference);
  const [plant, setPlant]= useState(sensor.plant);
  const [p_line, setP_line]= useState(sensor.place.line);
  const [p_column, setP_column]= useState(sensor.place.column);

  const zones = useSelector(state => state.zones.zones.data);
  const loras = useSelector(state => state.loras.loras.data);
  useEffect(() => {
    dispatch(fetchZones());
  }, [zones]);

  useEffect(()=>{
    dispatch(fetchLorasByZone(selectedZone.value._id));
  },[selectedZone]);

  const zoneOptions = [];
  zones && zones.map((zone) => {
    let zoneLabel = zone.title + ' | ' + zone.field.title;
    let option = { value: zone, label: zoneLabel };
    zoneOptions.push(option);
  });
  const loraOptions = [];
  loras && loras.map((lora) => {
    let loraLabel = lora.title + ' (' + lora.device + ')';
    let option = { value: lora, label: loraLabel };
    loraOptions.push(option);
  });

  const [selectedType, setSelectedType] = useState({ value: sensor.type, label: sensor.type});

  const typeOptions = [
    { value: 'soil humidity', label: 'Soil Humidity'},
    { value: 'air humidity', label: 'Air Humidity'},
    { value: 'temperature', label: 'Temperature'},
    { value: 'soil temperature', label: 'Soil Temperature'},
    { value: 'npk', label: 'NPK'},
    { value: 'rain', label: 'Rain'}
  ];
  

  const handleSubmit = (e) => {
    e.preventDefault();
    let lorabox = selectedLora.value ? {
      id: selectedLora.value._id,
      title: selectedLora.value.title,
      macAddr: selectedLora.value.macAddr,
      connected: selectedLora.value.connected,
      device: selectedLora.value.device,
      position: selectedLora.value.position,
      isActive: selectedLora.value.isActive
    } : null;
    const sensorToUpdate = {
      title: e.target.title.value,
      title_tr: {
        en: e.target.title.value,
        fr: e.target.title_fr?.value,
        ar: e.target.title_ar?.value,
      },
      zone: selectedZone.value,
      zoneId: selectedZone.value._id,
      field: selectedZone.value.field._id,
      lorabox: lorabox,
      loraboxId: selectedLora.value._id,
      description: e.target.description.value,
      description_tr: {
        en: e.target.description.value,
        fr: e.target.description_fr?.value,
        ar: e.target.description_ar?.value,
      },
      type: selectedType.value,
      reference: e.target.reference.value,
      plant: e.target.plant.value,
      place: {
        line: e.target.p_line.value,
        column: e.target.p_column.value
      }
    }
    if(sensor.loraboxId && sensor.loraboxId !== selectedLora.value?._id)
      dispatch(removeFromLora(sensor.loraboxId, sensor._id))
    dispatch(addToLora(selectedLora.value._id ,sensor._id));
    dispatch(editSensor(sensor._id, sensorToUpdate));
    showIt(false);
    setAlert({
      isOpen: true,
      type: 'success',
      message: 'Sensor edited successfully !'
    })
  }

  return (
    <div>
      <Paper style={{ padding: 30 }}>
        <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
          <Typography variant="h5" color="primary" style={{ marginBottom: 30 }} >
            Edit Sensor
          </Typography>
          <Button onClick={()=>setOpenTranslation(!openTranslation)}><GTranslateIcon color='primary' /></Button>
        </div>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField id="title" name="title" label="Sensor Title" fullWidth value={title} onChange={(e)=>setTitle(e.target.value)} />
            </Grid>
            {openTranslation && (<>
            <Grid item xs={12} md={6}>
              <TextField name="title_fr" label="Sensor Title (FR)" fullWidth value={titleFr} onChange={(e)=>setTitleFr(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="title_ar" label="Sensor Title (AR)" fullWidth value={titleAr} onChange={(e)=>setTitleAr(e.target.value)} />
            </Grid>
            </>)}
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <Typography color='textSecondary'>Zone</Typography>
                <Select
                  defaultValue={selectedZone}
                  onChange={setSelectedZone}
                  options={zoneOptions}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <Typography color='textSecondary'>LoRa Box</Typography>
                <Select
                  defaultValue={selectedLora}
                  onChange={setSelectedLora}
                  options={loraOptions}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField id="description" name="description" label="Description" fullWidth multiline value={description} onChange={(e)=>setDescription(e.target.value)} />
            </Grid>
            {openTranslation && (<>
            <Grid item xs={12}>
              <TextField name="description_fr" label="Sensor Description (FR)" fullWidth value={descriptionFr} onChange={(e)=>setDescriptionFr(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="description_ar" label="Sensor Description (AR)" fullWidth value={descriptionAr} onChange={(e)=>setDescriptionAr(e.target.value)} />
            </Grid>
            </>)}
            <Grid item xs={12} md={6}>
              <Typography color='textSecondary'>Sensor Type</Typography>
              <FormControl className={classes.formControl} fullWidth>
                <Select
                  defaultValue={selectedType}
                  onChange={setSelectedType}
                  options={typeOptions}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField id="reference" name="reference" label="Reference" fullWidth value={reference} onChange={(e)=>setReference(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField id="plant" name="plant" label="Plant" fullWidth value={plant} onChange={(e)=>setPlant(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography color='textSecondary'>Place</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField id="p_line" name="p_line" label="Line" fullWidth type='number' value={p_line} onChange={(e)=>setP_line(e.target.value)} />
                </Grid>
                <Grid item xs={4}>
                  <TextField id="p_column" name="p_column" label="Column" fullWidth type='number' value={p_column} onChange={(e)=>setP_column(e.target.value)} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              type="submit"
            >
              Save
            </Button>
            <Button variant="contained" onClick={() => showIt(false)} style={{ marginRight: 20 }}>
              Cancel
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  )
}

export default EditSensor;