export const ActionTypes = {
    //Users Actions
    LOGIN: 'LOGIN',
    REGISTER: 'REGISTER',
    LOGOUT: 'LOGOUT',
    FETCH_USERS: 'FETCH_USERS',
    ACTIVATE_USER: 'ACTIVATE_USER',
    ADD_USER: 'ADD_USER',
    EDIT_USER: 'EDIT_USER',
    DELETE_USER: 'DELETE_USER',

    //Fields Actions
    FETCH_FIELDS: 'FETCH_FIELDS',
    ACTIVATE_FIELD: 'ACTIVATE_FIELD',
    ADD_FIELD: 'ADD_FIELD',
    EDIT_FIELD: 'EDIT_FIELD',
    DELETE_FIELD: 'DELETE_FIELD',

    //Zones Actions
    FETCH_ZONES_BY_USER: 'FETCH_ZONES_BY_USER',
    SELECTED_ZONE: 'SELECTED_ZONE',
    FETCH_ZONES: 'FETCH_ZONES',
    ACTIVATE_ZONE: 'ACTIVATE_ZONE',
    ADD_ZONE: 'ADD_ZONE',
    EDIT_ZONE: 'EDIT_ZONE',
    DELETE_ZONE: 'DELETE_ZONE',

    //Sensors Actions
    FETCH_SENSORS_BY_ZONE: 'FETCH_SENSORS_BY_ZONE',
    FETCH_SENSORS_BY_FIELD: 'FETCH_SENSORS_BY_FIELD',
    FETCH_SENSORS: 'FETCH_SENSORS',
    ADD_SENSOR: 'ADD_SENSOR',
    EDIT_SENSOR: 'EDIT_SENSOR',
    DELETE_SENSOR: 'DELETE_SENSOR',

    //Lora Actions
    FETCH_LORAS_BY_ZONE: 'FETCH_LORAS_BY_ZONE',
    FETCH_LORAS_BY_FIELD: 'FETCH_LORAS_BY_FIELD',
    FETCH_LORAS: 'FETCH_LORAS',
    ADD_LORA: 'ADD_LORA',
    EDIT_LORA: 'EDIT_LORA',
    DELETE_LORA: 'DELETE_LORA',
    ACTIVATE_LORA: 'ACTIVATE_LORA',

    //Vannes Actions
    FETCH_VANNES_BY_ZONE: 'FETCH_VANNES_BY_ZONE',
    OPEN_CLOSE_VANNE: 'OPEN_CLOSE_VANNE',
    FETCH_VANNES: 'FETCH_VANNES',
    ACTIVATE_VANNE: 'ACTIVATE_VANNE',
    ADD_VANNE: 'ADD_VANNE',
    EDIT_VANNE: 'EDIT_VANNE',
    DELETE_VANNE: 'DELETE_VANNE',
    ADD_PERIODS: 'ADD_PERIODS',
    DELETE_PERIOD: 'DELETE_PERIOD'
}