import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { logout } from '../../../redux/actions/userActions';
import Fields from '../fields';
import Loras from '../lora';
import Sensors from '../sensors';
import Users from '../users';
import Vannes from '../vannes';
import Zones from '../zones';

export default function Router() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(()=>{
    if(user === null || typeof(user) === 'undefined') {
      dispatch(logout());
      localStorage.setItem('user', null);
      navigate('/login');
    }
  },[]);
  return (
    <Routes>
        <Route path="/" element={<Users />} />
        <Route path="/users" element={<Users />} />
        <Route path="/fields" element={<Fields />} />
        <Route path="/zones" element={<Zones />} />
        <Route path="/loras" element={<Loras />} />
        <Route path="/vannes" element={<Vannes />} />
        <Route path="/sensors" element={<Sensors />} />
    </Routes>
  )
}
