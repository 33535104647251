import innoApi from '../../apis/innoApi';
import {ActionTypes} from '../constants/action-types';

export const fetchLorasByZone = (zone) => {
    return async (dispatch) => {
        const response = await innoApi.get(`lora/zone/${zone}`);
        dispatch({type: ActionTypes.FETCH_LORAS_BY_ZONE, payload: response});
    }
}

export const fetchLorasByField = (field) => {
    return async (dispatch) => {
        const response = await innoApi.get(`lora/field/${field}`);
        dispatch({type: ActionTypes.FETCH_LORAS_BY_FIELD, payload: response});
    }
}

export const fetchLoras = () => {
    return async (dispatch) => {
        const response = await innoApi.get('lora');
        dispatch({type: ActionTypes.FETCH_LORAS, payload: response});
    }
}

export const addLora = (lora) => {
    return async (dispatch) => {
        const response = await innoApi.post('lora/', lora);
        dispatch({type: ActionTypes.ADD_LORA, payload: response});
    }
}

export const editLora = (id, lora) => {
    return async (dispatch) => {
        const response = await innoApi.put(`lora/${id}`, lora);
        dispatch({type: ActionTypes.EDIT_LORA, payload: response});
    }
}

export const deleteLora = (id) => {
    return async (dispatch) => {
        const response = await innoApi.delete(`lora/${id}`);
        dispatch({type: ActionTypes.DELETE_LORA, payload: response});
    }
}

export const activateLora = (id, status) => {
    return async (dispatch) => {
        const response = await innoApi.put(`lora/activate/${id}/${status}`);
        dispatch({type: ActionTypes.ACTIVATE_LORA, payload: response});
    }
}