import React, {useEffect, useState} from 'react';
import { Button, Modal, Paper, Switch, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Typography, Grid
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './style';
import { activateField, deleteField, fetchFields } from '../../../redux/actions/fieldActions';
// import NewField from './newField';
// import EditField from './editField';
import Map from './map';
import { activateLora, deleteLora, fetchLoras } from '../../../redux/actions/loraActions';
import NewLora from './new';
import EditLora from './editLora';
//import { FieldDetails } from './details';

const Loras = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showNewForm, setShowNewForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState(null);
  const [selectedLora, setSelectedLora] = useState(null);
  const [showMap, setShowMap] = useState(false);

  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    message: ''
  })

  const loras = useSelector(state => state.loras.loras.data);
  useEffect(()=>{
    dispatch(fetchLoras());
  },[loras]);
  let i = 1;

  const activate = (e) => {
    dispatch(activateLora(e.target.name, e.target.checked));
  }

  const onDeleteClick = (id, title) => {
    setOpenModal(true);
    setDeleteId(id);
    setDeleteName(title);
  }

  const handleCancel = () => {
    setDeleteId(null);
    setDeleteName(null);
    setOpenModal(false);
  }

  const handleEdit = (lora) => {
    setShowEditForm(true);
    setSelectedLora(lora);
  }

  const handleDetails = (lora) => {
    setShowDetails(true);
    setSelectedLora(lora);
  }

  const handleDelete = () => {
    dispatch(deleteLora(deleteId));
    setOpenModal(false);
    setAlert({
      isOpen: true,
      type: 'success',
      message: 'Lora Box deleted successfully !'
    })
  }

  useEffect(()=>{
    alert.isOpen && setTimeout(() => {
      setAlert({isOpen: false, type: '', message: ''})
    }, "3000")
  })

  const columns = ['N°', 'ID', 'Title', 'ESP Addr', 'Type', 'Device', 'Field', 'Zone', 'Active', 'Actions'];
  
  const capitalize = (word) => (word.charAt(0).toUpperCase()+word.slice(1))
  
  return (
    <div>
      {alert.isOpen && (
        <Alert variant="filled" severity={alert.type} onClose={()=>{setAlert({isOpen: false})}} className={classes.alert}>
          {alert.message}
        </Alert>
      )}
      <div className={classes.between}>
        <Typography variant="h4" color="primary" style={{paddingLeft: 30}}>
          LoRa Boxes
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>setShowNewForm(true)}
          className={classes.newButton}
          startIcon={<AddIcon />}
        >
          New LoRa Box
        </Button>
      </div>
      
      <Grid container spacing={3} className={classes.mainContainer}>
        <Grid item xs={12} lg={(showNewForm || showEditForm || showDetails) ? (7) : (12)}>
          <TableContainer component={Paper} className={classes.paperPad}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column)=>(<TableCell key={column}>{column}</TableCell>))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loras && loras.map((lora) => (
                  <TableRow key={lora._id}>
                    <TableCell>{i++}</TableCell>
                    <TableCell>{lora._id}</TableCell>
                    <TableCell>{lora.title}</TableCell>
                    <TableCell>{lora.macAddr}</TableCell>
                    <TableCell>{capitalize(lora.type)}</TableCell>
                    <TableCell>{capitalize(lora.device)}</TableCell>
                    <TableCell>{lora.zone?.field.title} - {lora.zone?.field.user.firstname}</TableCell>
                    <TableCell>{lora.zone.title}</TableCell>
                    <TableCell>
                      <Switch
                        checked={lora.isActive}
                        onClick={activate}
                        color="secondary"
                        name={lora._id}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.actionIcons}>
                        {/* <LaunchIcon className={classes.actionIcon} onClick={()=> handleDetails(lora)} /> */}
                        <EditIcon className={classes.actionIcon} onClick={()=>handleEdit(lora)} />
                        <DeleteIcon className={classes.actionIcon} onClick={()=>onDeleteClick(lora._id, lora.title)} />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {showNewForm && (
        <Grid item xs={12} lg={5}>
          <NewLora showIt={setShowNewForm} setAlert={setAlert}/>
        </Grid>
        )}
         
        {showEditForm && (
        <Grid item xs={12} lg={5}>
          <EditLora showIt={setShowEditForm} lora={selectedLora} setAlert={setAlert}/>
        </Grid>
        )}
        {/* 
        {showDetails && (
        <Grid item xs={12} lg={5}>
          <FieldDetails showIt={setShowDetails} lorabox={selectedLora}/>
        </Grid>
        )} */}
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCancel}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalPaper}>
          <Typography variant="h5" color='primary' gutterBottom>
            Delete Lora Box
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete <b>{deleteName}</b> ?
          </Typography>
          <br/>
          <div className={classes.flexRight}>
            <Button variant="contained" size='small' className={classes.modalBtn} color="primary" onClick={handleDelete}>
              Delete
            </Button>
            <Button variant="contained" size='small' className={classes.modalBtn} onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <div className={classes.switchMap}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <Typography variant="body1" color='primary'>
            Show Map
          </Typography>
          <Switch
            checked={showMap}
            onClick={()=>setShowMap(!showMap)}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </div>
      {showMap && (
      <Paper>
        <Map loras={loras} />
      </Paper>
      )}
    </div>
  )
}
export default Loras;