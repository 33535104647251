import { Button, Checkbox, Divider, FormControlLabel, Grid, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deletePeriod, fetchVannesByZone, openCloseVanne } from '../../../../redux/actions/vanneActions';
import useStyles from './style';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import Schedule from './schedule';
import innoApi from '../../../../apis/innoApi';




export const Vannes = ({zone, setAlert}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const vannes = useSelector(state => state.vannes.vannes.data);
    //const [tmpVn, setTmpVn] = useState(vannes);
    const [tmoutErr, setTmoutErr] = useState([]);
    const [openPlanif, setOpenPlanif] = useState(false);
    const handleDeletePeriod = (vanne, period) => {
        dispatch(deletePeriod(vanne, period));
    }
    //useEffect(()=>{},[vannes])
    useEffect(() => {
        const interval = setInterval(() => {
            if(typeof(vannes)!=='undefined') {
            vannes.map((vanne) => {
                vanne.status!==vanne.statusAck 
                ? setTmoutErr(prev => [...prev, vanne._id])
                : setTmoutErr(prev => prev.filter(id => id!==vanne._id ))
            })
            }
        }, 300000);
        return () => clearInterval(interval); 
    },[])
    const handleChange = (e) => {
        let id = e.target.name;
        let state = '';
        if(e.target.checked)
            state = 'on';
        else
            state = 'off';

        dispatch(openCloseVanne(id, state));
    };

    useEffect(()=>{
        dispatch(fetchVannesByZone(zone));
    },[vannes]);

    const renderDay = (day) => {
        switch(day) {
            case 0: return 'Dim';
            case 1: return 'Lun';
            case 2: return 'Mar';
            case 3: return 'Mer';
            case 4: return 'Jeu';
            case 5: return 'Ven';
            case 6: return 'Sam';
        }
    }

    const timelapse = (from, to) => {
        let dateNow = new Date();
        let fhr = new Date(from).getHours()-1;
        let fmn = new Date(from).getMinutes();
        let thr = new Date(to).getHours()-1;
        let tmn = new Date(to).getMinutes();
        if(new Date(from).getHours()===0 && (fhr=23));
        if(new Date(to).getHours()===0 && (thr=23));
        if(dateNow.getHours() === fhr) {
            if(fmn > dateNow.getMinutes() && fmn-dateNow.getMinutes()<6)
            return(<Typography variant="caption" color="primary">
                L'irrigation commance dans {(fmn-dateNow.getMinutes()=== 1) ? ('1 minute') : (fmn-dateNow.getMinutes()+' minutes')}
            </Typography>)
        }
    }

    const handleActivatePeriod = (e, vanneId, periodId) => {
        innoApi.put(`vannes/periods/activate/${vanneId}/${periodId}/${e.target.checked}`)
    }

  return (
    <>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
        <Typography variant="h5" color="primary">
            Contrôle de vannes
        </Typography>
        <Button
            variant="contained"
            color="primary"
            startIcon={<AddAlarmIcon />}
            type="button"
            onClick={()=>setOpenPlanif(true)}
        >
            Planifier
        </Button>
    </div>
    { vannes && vannes.map(vanne => {
        let vanneOn = false;
        if(vanne.status === 'on')
            vanneOn = true;
        else
            vanneOn = false;
        return (
            <Grid container spacing={1} key={vanne._id}>
                <Grid item xs={2}>
                    <Switch
                        checked={vanneOn}
                        onClick={handleChange}
                        color="primary"
                        name={vanne._id}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="h5">{vanne.title} {vanne.status === 'on' && ('('+vanne.debit + ' ' + vanne.unitDebit + ')')}</Typography>
                </Grid>
                <Grid item sm={2}></Grid>
                {vanne.statusAck === 'on' && vanne.status === 'on' && (<Typography variant="subtitle1" color='secondary'>En cours d'irrigation...</Typography>)}
                {tmoutErr && tmoutErr.length>0 && tmoutErr.includes(vanne._id) && (
                    <Typography variant="subtitle1" color='error'>Error</Typography>
                )}
            </Grid>
        )
    } )}
    <Divider style={{marginTop: 20, marginBottom: 20}} />
    <div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography className={classes.subtitle} variant="h5" color="primary" style={{marginBottom: 20}}>
                Horaires d'irrigation
            </Typography>
            {/* <AddAlarmIcon
                onClick={()=>setOpenPlanif(true)}
                className={classes.addPeriodBtn}
            /> */}
        </div>
        { vannes && vannes.map(vanne => 
            vanne.periods && vanne.periods.length > 0 && (
                <Paper style={{marginBottom: 20, padding: 16}}>
                    <Typography variant="subtitle2" color="primary" style={{marginBottom: 10}}>
                        {vanne.title}
                    </Typography>
                    {vanne.periods.map((period, index) => {
                        let from = period.from;
                        let fromtime = from.split('T')[1].split(':');
                        let fromHrs = fromtime[0];
                        let fromMns = fromtime[1];
                        
                        let to = period.to;
                        let totime = to.split('T')[1].split(':');
                        let toHrs = totime[0];
                        let toMns = totime[1];

                        let fromDate = period.fromDate;
                        let fromYear = new Date(fromDate).getFullYear();
                        let fromMonth = new Date(fromDate).getMonth()+1;
                        let fromDay = new Date(fromDate).getDate();

                        let toDate = period.toDate;
                        let toYear = new Date(toDate).getFullYear();
                        let toMonth = new Date(toDate).getMonth()+1;
                        let toDay = new Date(toDate).getDate();


                        return(
                            <div key={period._id} className={(index < vanne.periods.length -1) ? classes.periodRowHasBorder : classes.periodRow}>
                                <div>
                                    <div className={classes.periodLeft}>
                                        <Typography variant="body1" color="inherit" style={{marginRight: 4, fontWeight:'bold'}}>
                                            {fromHrs}:{fromMns} - {toHrs}:{toMns} | 
                                        </Typography>
                                        <Typography variant="body1" style={{color: 'gray'}}>
                                            { period.repeat ?
                                                period.dayly 
                                                ? ('Chaque jour') 
                                                : period.days.map((day, index) => (
                                                    (index < period.days.length-1) ? (renderDay(day)+ ' - ') : (renderDay(day)))
                                                )
                                              : ('Une seule fois')
                                            }
                                        </Typography>
                                    </div>
                                    <div className={classes.periodLeft}>
                                        <Typography variant="caption" style={{color: 'gray'}}>
                                            {! period.forever && ('De ' + fromDay + '/' + fromMonth + '/' + fromYear + ' au ' + toDay + '/' + toMonth + '/' + toYear )}
                                        </Typography>
                                        {!period.repeat && timelapse(period.from, period.to)}
                                    </div>
                                </div>
                                <div style={{display: 'inline-flex', alignItems: 'flex-start'}}>
                                    <FormControlLabel
                                        style={{marginTop: -6}}
                                        control={<Switch 
                                            checked={period.isActive} 
                                            name={'active'} 
                                            color='primary' 
                                            onChange={(e)=>handleActivatePeriod(e, vanne._id, period._id)}
                                        />}
                                    />
                                    <DeleteIcon
                                        color='primary' 
                                        fontSize='medium'
                                        onClick = {()=> handleDeletePeriod(vanne._id, period._id)}
                                        style={{cursor: 'pointer'}}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </Paper>
            )
        )}
    </div>
    <Schedule openPlanif={openPlanif} setOpenPlanif={setOpenPlanif} vannes={vannes} setAlert={setAlert}/>
    </>
  )
}
