import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    media: {
        height: 140
    },
    card: {
        maxWidth: 345
    },
    contentBox: {
        marginTop: 1
    },
    primaryMainText: {
        color: theme.palette.primary.main
    },
    link: {
        textDecoration: 'none',
    },
    detailBtn: {
        color: theme.palette.primary.main,
        paddingRight: 15,
        paddingLeft: 15,
        paddingTop: 3,
        paddingBottom: 3,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
        borderRadius: 50,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        },
    }
}));

export default useStyles;