import { ActionTypes } from "../constants/action-types"

const initialState = {
    zones: []
}

export const zonesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_ZONES_BY_USER:
      return { ...state, zones: payload };
    case ActionTypes.FETCH_ZONES:
      return { ...state, zones: payload };
    default:
      return state;
  }
};

export const selectedZoneReducer = (state = {}, {type, payload}) => {
  switch(type) {
    case ActionTypes.SELECTED_ZONE || ActionTypes.EDIT_ZONE || ActionTypes.ACTIVATE_ZONE || ActionTypes.DELETE_ZONE:
      return { ...state, ...payload }
    default:
      return state;
  }
}