import axios from "axios";

const connectedUser = localStorage.getItem('user');
let token = null;
if(typeof(connectedUser) !=='undefined' && connectedUser!== null) {
    if(JSON.parse(connectedUser) !== null) {
        token = JSON.parse(connectedUser).access_token
    }
}


export default axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    //baseURL: 'http://localhost:3000/',
    headers: {"Authorization" : `Bearer ${token}`}
})