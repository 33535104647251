import innoApi from '../../apis/innoApi';
import {ActionTypes} from '../constants/action-types';

export const fetchFields = () => {
    return async (dispatch) => {
        const response = await innoApi.get('fields');
        dispatch({type: ActionTypes.FETCH_FIELDS, payload: response});
    }
}

export const addField = (field) => {
    return async (dispatch) => {
        const response = await innoApi.post('fields/', field);
        dispatch({type: ActionTypes.ADD_FIELD, payload: response});
    }
}

export const activateField = (id, status) => {
    return async (dispatch) => {
        const response = await innoApi.put(`fields/activate/${id}/${status}`);
        dispatch({type: ActionTypes.ACTIVATE_FIELD, payload: response});
    }
}

export const editField = (id, field) => {
    return async (dispatch) => {
        const response = await innoApi.put(`fields/${id}`, field);
        dispatch({type: ActionTypes.EDIT_FIELD, payload: response});
    }
}

export const deleteField = (id) => {
    return async (dispatch) => {
        const response = await innoApi.delete(`fields/${id}`);
        dispatch({type: ActionTypes.DELETE_FIELD, payload: response});
    }
}