import innoApi from '../../apis/innoApi';
import {ActionTypes} from '../constants/action-types';

export const fetchZonesByUser = (id) => {
    return async (dispatch) => {
        const response = await innoApi.get(`zones/user/${id}`);
        dispatch({type: ActionTypes.FETCH_ZONES_BY_USER, payload: response});
    }
}

export const fetchZone = (id) => {
    return async(dispatch) => {
        const response = await innoApi.get(`zones/${id}`);
        dispatch({type: ActionTypes.SELECTED_ZONE, payload: response});
    }
}

export const fetchZones = () => {
    return async (dispatch) => {
        const response = await innoApi.get('zones');
        dispatch({type: ActionTypes.FETCH_ZONES, payload: response});
    }
}

export const addZone = (zone) => {
    return async (dispatch) => {
        const response = await innoApi.post('zones/', zone);
        dispatch({type: ActionTypes.ADD_ZONE, payload: response});
    }
}

export const activateZone = (id, status) => {
    return async (dispatch) => {
        const response = await innoApi.put(`zones/activate/${id}/${status}`);
        dispatch({type: ActionTypes.ACTIVATE_ZONE, payload: response});
    }
}

export const editZone = (id, zone) => {
    return async (dispatch) => {
        const response = await innoApi.put(`zones/${id}`, zone);
        dispatch({type: ActionTypes.EDIT_ZONE, payload: response});
    }
}

export const deleteZone = (id) => {
    return async (dispatch) => {
        const response = await innoApi.delete(`zones/${id}`);
        dispatch({type: ActionTypes.DELETE_ZONE, payload: response});
    }
}