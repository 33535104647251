import { ActionTypes } from "../constants/action-types"

const initialState = {
    sensors: []
}

export const sensorsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_SENSORS:
      return { ...state, sensors: payload };
    case ActionTypes.FETCH_SENSORS_BY_ZONE:
      return { ...state, sensors: payload };
    case ActionTypes.FETCH_SENSORS_BY_FIELD:
      return { ...state, sensors: payload };
    default:
      return state;
  }
};

export const sensorReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ActionTypes.ADD_SENSOR:
      return {...state, ...payload};
    case ActionTypes.EDIT_SENSOR:
      return {...state, ...payload};
    case ActionTypes.DELETE_SENSOR:
      return {...state, ...payload};
    default:
      return state;
  }
};