import { ActionTypes } from "../constants/action-types"

const initialState = {
    users: []
}

export const userReducer = (state = {}, {type, payload}) => {
  switch(type) {
    case ActionTypes.LOGIN:
      return { ...state, ...payload};
    case ActionTypes.LOGOUT:
      return state;
    case ActionTypes.REGISTER:
      return { ...state, ...payload};
    case ActionTypes.ACTIVATE_USER:
      return { ...state, ...payload};
    case ActionTypes.ADD_USER:
      return { ...state, ...payload};
    case ActionTypes.EDIT_USER:
      return { ...state, ...payload};
    default:
      return state;
  }
}

export const usersReducer = (state = initialState, {type, payload}) => {
  switch(type) {
    case ActionTypes.FETCH_USERS:
      return { ...state, users: payload};
    default:
      return state;
  }
}