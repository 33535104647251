import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Grid, Paper, Typography, useTheme} from '@material-ui/core';
import { WiDayStormShowers, WiDayThunderstorm, WiDayCloudyGusts, WiDaySunnyOvercast, WiDaySunny, WiHumidity,
  WiDirectionUp, WiDirectionUpRight, WiDirectionUpLeft, WiDirectionRight, WiDirectionLeft, WiDirectionDown, WiDirectionDownRight, WiDirectionDownLeft
} from 'weather-icons-react';
import sunnyDay from './img/sunny-day.jpg';
import { CenterFocusStrong } from '@material-ui/icons';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ToysIcon from '@material-ui/icons/Toys';
import useStyles from './style';

export default function Weather() {

    let [currentWeather, setCurrentWeather] = useState(null);
    let [weatherRendered, setWeatherRendered] = useState(false);
    const classes = useStyles()
    const screenSize = window.screen.width;

    useEffect(() => {
        axios.get('https://api.openweathermap.org/data/2.5/weather?lat=36.174485&lon=8.7120093&appid=3397467fd224a058bfd877844ea56fd3&units=metric&lang=fr')
        .then(res => {
            setCurrentWeather(res.data);
            setWeatherRendered(true);
            windDirection();
        });
        
    },[weatherRendered]);

    const renderSwitch = () => {
      if(currentWeather !== null) {
      switch (currentWeather.weather[0].id) {
        case 200:
        case 201:
          return(<WiDayStormShowers size={screenSize>380 ? 200 : 100} color='#FFFFFF' />)
        case 202:
          return(<WiDayThunderstorm size={screenSize>380 ? 200 : 100} color='#FFFFFF' />)
        case 800:
          return(<WiDaySunny size={screenSize>380 ? 200 : 100} color='#FFFFFF' />)
        case 801:
          return(<WiDaySunnyOvercast size={screenSize>380 ? 200 : 100} color='#FFFFFF' />)
        case 802:
          return(<WiDayCloudyGusts size={screenSize>380 ? 200 : 100} color='#FFFFFF' />)   
        default:
          break;
      }
      }
    }
    const windDirection = () => {
      if(currentWeather !== null) {
        if (currentWeather.wind.deg >= 348.75 || currentWeather.wind.deg <= 11.25) {
          return(
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Typography variant="h5" color="inherit" paragraph>
                Direction : <b className={classes.secondaryText}>Nord</b>
              </Typography>
              <WiDirectionUp style={{height: 50, width: 50, marginTop: -10}} />
            </div>
          )
        } else if(currentWeather.wind.deg > 11.25 && currentWeather.wind.deg < 78.75) {
          return(
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Typography variant="h5" color="inherit" paragraph>
                Direction : <b className={classes.secondaryText}>Nord-Est</b>
              </Typography>
              <WiDirectionUpRight style={{height: 50, width: 50, marginTop: -10}} />
            </div>
          )
        }
        else if(currentWeather.wind.deg >= 78.75 && currentWeather.wind.deg <= 101.25) {
          return(
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Typography variant="h5" color="inherit" paragraph>
                Direction : <b className={classes.secondaryText}>Est</b>
              </Typography>
              <WiDirectionRight style={{height: 50, width: 50, marginTop: -10}} />
            </div>
          )
        }
        else if(currentWeather.wind.deg > 101.25 && currentWeather.wind.deg < 168.75) {
          return(
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Typography variant="h5" color="inherit" paragraph>
                Direction : <b className={classes.secondaryText}>Sud-Est</b>
              </Typography>
              <WiDirectionDownRight style={{height: 50, width: 50, marginTop: -10}} />
            </div>
          )
        }
        else if(currentWeather.wind.deg >= 168.75 && currentWeather.wind.deg <= 191.25) {
          return(
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Typography variant="h5" color="inherit" paragraph>
                Direction : <b className={classes.secondaryText}>Sud</b>
              </Typography>
              <WiDirectionDown style={{height: 50, width: 50, marginTop: -10}} />
            </div>
          )
        }
        else if(currentWeather.wind.deg > 191.25 && currentWeather.wind.deg < 258.75) {
          return(
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Typography variant="h5" color="inherit" paragraph>
                Direction : <b className={classes.secondaryText}>Sud-Ouest</b>
              </Typography>
              <WiDirectionDownLeft  style={{height: 50, width: 50, marginTop: -10}} />
            </div>
          )
        }
        else if(currentWeather.wind.deg >= 258.75 && currentWeather.wind.deg <= 281.25) {
          return(
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Typography variant="h5" color="inherit" paragraph>
                Direction : <b className={classes.secondaryText}>Ouest</b>
              </Typography>
              <WiDirectionLeft style={{height: 50, width: 50, marginTop: -10}} />
            </div>
          )
        }
        else if(currentWeather.wind.deg > 281.25 && currentWeather.wind.deg < 348.75) {
          return(
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Typography variant="h5" color="inherit" paragraph>
                Direction : <b className={classes.secondaryText}>Nord-Ouest</b>
              </Typography>
              <WiDirectionUpLeft style={{height: 50, width: 50, marginTop: -10}} />
            </div>
          )
        }
      }
    }
    return (
        <div>
          <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${sunnyDay})` }}>
            <div className={classes.overlay} />
            
            {(currentWeather !== null) &&
            <div className={classes.mainFeaturedPostContent}>
                <Grid container spacing={3}>
                  <Grid item xs={0} md={4}></Grid>
                  <Grid container md={4} style={{alignItems: 'center'}}>
                    <Grid item xs={6} style={{ textAlign: 'right'}}>
                      {renderSwitch()}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component={screenSize>380 ? 'h1' : 'h2'} variant={screenSize>380 ? 'h1' : 'h2'} color="inherit">
                        {Math.round(currentWeather.main.temp)}°
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={0} md={4}></Grid>
                </Grid>
                <Grid container spacing={1} xs={4}></Grid>
              <Typography component={screenSize>380 ? 'h2' : 'h4'} variant={screenSize>380 ? 'h2' : 'h4'} color="inherit" gutterBottom style={{ textTransform: 'capitalize' }}>
                {currentWeather.weather[0].description}
              </Typography>
              <Typography component={screenSize>380 ? 'h5' : 'h6'} variant={screenSize>380 ? 'h5' : 'h6'} color="inherit" paragraph>
                {currentWeather.name}
              </Typography>
            </div>
            }
          </Paper>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Paper style={{padding: '2rem' }}>
                <div style={{display: 'flex', justifyContent: 'center', marginLeft: -25}}>
                  <WiHumidity style={{height: 70, width: 70, marginTop: -15}} />
                  <Typography component="h4" variant="h4" color="inherit">
                    Humidité
                  </Typography>
                </div>
                {(currentWeather !== null) &&
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
                <div style={{ width: 150, height: 150 }}>
                    <CircularProgressbar value={currentWeather.main.humidity} text={`${currentWeather.main.humidity}%`} styles={buildStyles({
                        pathColor: '#18b272',
                        textColor: '#105650'
                    })}/>
                </div>
                </div>
                }
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper style={{textAlign: 'center', padding: '2rem' }}>
                <div style={{display: 'flex', justifyContent: 'center', marginLeft: -25, marginBottom: 20}}>
                  <ToysIcon style={{height: 50, width: 50, marginTop: -5, marginRight: 10}} />
                  <Typography component="h4" variant="h4" color="inherit">
                    Vent
                  </Typography>
                </div>
                {(currentWeather !== null) &&
                  windDirection()
                }
                {(currentWeather !== null) &&
                <Typography variant="h5" color="inherit" paragraph>
                  Vitesse : <b className={classes.secondaryText}>{Math.round(currentWeather.wind.speed * 3.6)} km/h</b>
                </Typography>
                }
              </Paper>
            </Grid>
          </Grid>
        </div>
    )
}