import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchZone } from '../../../../redux/actions/zoneActions';
import farmImage from '../img/farm.png';
import useStyles from './style';
import { Sensors } from './sensors';
import { Vannes } from './vannes';
import Alert from '@material-ui/lab/Alert';

const Zone = () => {
    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const sw = window.innerWidth;
    const zone = useSelector((state) => state.selectedZone.data);
    useEffect(() => {
        dispatch(fetchZone(params.id))
    },[]);

    useEffect(()=>{
        alert.isOpen && setTimeout(() => {
          setAlert({isOpen: false, type: '', message: ''})
        }, "3000")
      })

    const [alert, setAlert] = useState({
        isOpen: false,
        type: '',
        message: ''
      })
      
    return (
        <>
        {zone && (
        <Grid container spacing={4}>
            <Grid item xs={12}>
            <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${farmImage})` }}>
                <Grid container>
                    <Grid item md={6}>
                    <div className={classes.mainFeaturedPostContent}>
                        <Typography variant={sw < 600 ? 'h5' : 'h3'} color="inherit" gutterBottom>
                            {zone.title}
                        </Typography>
                    </div>
                    </Grid>
                </Grid>
            </Paper>
            </Grid>
            {alert.isOpen && (
                <Grid item xs={12}>
                <Alert variant="filled" severity={alert.type} onClose={()=>{setAlert({isOpen: false})}} className={classes.alert}>
                    {alert.message}
                </Alert>
                </Grid>
            )}
            <Grid item xs={12}>
                <Typography className={classes.subtitle} variant="h4" color="primary">
                    Informations générales sur la parcelle
                </Typography>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={12} md={4}>
                    <Typography variant="body1" style={{marginBottom: 16}}>
                        Irrigation {zone.irrigMode === 'man' ? ('Manuelle') : ('Automatique')}
                    </Typography>
                    <Typography variant="body1">
                        Surface : <b className={classes.primaryMainText}>{zone.area}m²</b>
                    </Typography>
                </Grid>
                <Grid container item xs={12} md={8}>
                    <Typography variant='body1' color="inherit">
                        {zone.description}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
                <Sensors zone={zone._id} />
            </Grid>
            
            <Grid item xs={12} md={7}>
                <Vannes zone={zone._id} setAlert={setAlert}/>
            </Grid>
        </Grid>
        )}
        </>
    );
}

export default Zone;