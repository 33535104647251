import React, {useEffect, useState} from 'react';
import { Button, Modal, Paper, Switch, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Typography, Grid
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './style';
import { activateField, deleteField, fetchFields } from '../../../redux/actions/fieldActions';
import NewField from './newField';
import EditField from './editField';
import Map from './map';
import { FieldDetails } from './details';

const Fields = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showNewForm, setShowNewForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [showMap, setShowMap] = useState(false);

  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    message: ''
  })

  const fields = useSelector(state => state.fields.fields.data);
  useEffect(()=>{
    dispatch(fetchFields());
  },[fields]);
  let i = 1;

  const activate = (e) => {
    dispatch(activateField(e.target.name, e.target.checked));
  }

  const onDeleteClick = (id, title) => {
    setOpenModal(true);
    setDeleteId(id);
    setDeleteName(title);
  }

  const handleCancel = () => {
    setDeleteId(null);
    setDeleteName(null);
    setOpenModal(false);
  }

  const handleEdit = (field) => {
    setShowEditForm(true);
    setSelectedField(field);
  }

  const handleDetails = (field) => {
    setShowDetails(true);
    setSelectedField(field);
  }

  const handleDelete = () => {
    dispatch(deleteField(deleteId));
    setOpenModal(false);
    setAlert({
      isOpen: true,
      type: 'success',
      message: 'Field deleted successfully !'
    })
  }

  useEffect(()=>{
    alert.isOpen && setTimeout(() => {
      setAlert({isOpen: false, type: '', message: ''})
    }, "3000")
  })

  const columns = ['N°', 'Title', 'Owner', 'Address', 'Area', 'Active', 'Actions'];

  return (
    <div>
      {alert.isOpen && (
        <Alert variant="filled" severity={alert.type} onClose={()=>{setAlert({isOpen: false})}} className={classes.alert}>
          {alert.message}
        </Alert>
      )}
      <div className={classes.between}>
        <Typography variant="h4" color="primary" style={{paddingLeft: 30}}>
          Fields
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>setShowNewForm(true)}
          className={classes.newButton}
          startIcon={<AddIcon />}
        >
          New Field
        </Button>
      </div>
      
      <Grid container spacing={3} className={classes.mainContainer}>
        <Grid item xs={12} lg={(showNewForm || showEditForm || showDetails) ? (7) : (12)}>
          <TableContainer component={Paper} className={classes.paperPad}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column)=>(<TableCell key={column}>{column}</TableCell>))}
                </TableRow>
              </TableHead>
              <TableBody>
                {fields && fields.map((field) => (
                  <TableRow key={field._id}>
                    <TableCell>{i++}</TableCell>
                    <TableCell>{field.title}</TableCell>
                    <TableCell>{field.user.firstname} {field.user.lastname}</TableCell>
                    <TableCell>{field.state}, {field.city}</TableCell>
                    <TableCell>{field.area} Ha</TableCell>
                    <TableCell>
                      <Switch
                        checked={field.isActive}
                        onClick={activate}
                        color="secondary"
                        name={field._id}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.actionIcons}>
                        <LaunchIcon className={classes.actionIcon} onClick={()=> handleDetails(field)} />
                        <EditIcon className={classes.actionIcon} onClick={()=>handleEdit(field)} />
                        <DeleteIcon className={classes.actionIcon} onClick={()=>onDeleteClick(field._id, field.title)} />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {showNewForm && (
        <Grid item xs={12} lg={5}>
          <NewField showIt={setShowNewForm} setAlert={setAlert}/>
        </Grid>
        )}
         
        {showEditForm && (
        <Grid item xs={12} lg={5}>
          <EditField showIt={setShowEditForm} field={selectedField} setAlert={setAlert}/>
        </Grid>
        )}
        
        {showDetails && (
        <Grid item xs={12} lg={5}>
          <FieldDetails showIt={setShowDetails} selectedField={selectedField}/>
        </Grid>
        )}
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCancel}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalPaper}>
          <Typography variant="h5" color='primary' gutterBottom>
            Delete Field
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete <b>{deleteName}</b> ?
          </Typography>
          <br/>
          <div className={classes.flexRight}>
            <Button variant="contained" size='small' className={classes.modalBtn} color="primary" onClick={handleDelete}>
              Delete
            </Button>
            <Button variant="contained" size='small' className={classes.modalBtn} onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <div className={classes.switchMap}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <Typography variant="body1" color='primary'>
            Show Map
          </Typography>
          <Switch
            checked={showMap}
            onClick={()=>setShowMap(!showMap)}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </div>
      {showMap && (
      <Paper>
        <Map fields={fields} />
      </Paper>
      )}
    </div>
  )
}
export default Fields;