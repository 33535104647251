import { combineReducers } from "redux";
import { fieldReducer, fieldsReducer } from "./fieldReducer";
import { loraReducer, lorasReducer } from "./loraReducer";
import { sensorsReducer, sensorReducer } from "./sensorReducer";
import { userReducer, usersReducer } from "./userReducer";
import { vanneReducer, vannesReducer } from "./vanneReducer";
import { selectedZoneReducer, zonesReducer } from "./zoneReducer";

const reducers = combineReducers({
    user: userReducer,
    users: usersReducer,
    zones: zonesReducer,
    selectedZone: selectedZoneReducer,
    sensors: sensorsReducer,
    sensor: sensorReducer,
    vannes: vannesReducer,
    vanne: vanneReducer,
    fields: fieldsReducer,
    field: fieldReducer,
    loras: lorasReducer,
    lora: loraReducer,
});

export default reducers;