import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSensorsByZone } from '../../../../redux/actions/sensorActions';
import useStyles from './style';

export const Sensors = ({zone}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const sensors = useSelector(state => state.sensors.sensors.data);
    useEffect(()=>{
        dispatch(fetchSensorsByZone(zone));
    },[sensors]);
  return (
    <Paper className={classes.sensorsPaper} >
         <Typography className={classes.subtitle} variant="h5" color="primary">
            Valeurs des différents capteurs
        </Typography>
        <Grid container spacing={3}>
        { sensors && sensors.map(sensor => {
            let lastVal = sensor.values[sensor.values.length -1].value;
            let unity = sensor.values[sensor.values.length -1].unity;
            if(unity === '%')
            return (
            <Grid item xs={12} sm={6} key={sensor._id}>
                <Typography variant="body1" className={classes.sensorTitle}>
                    {sensor.title}
                </Typography>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
                    <div style={{ width: 150, height: 150 }}>
                        <CircularProgressbar value={lastVal} text={`${lastVal}%`} styles={buildStyles({
                            pathColor: '#18b272',
                            textColor: '#105650'
                        })}/>
                    </div>
                    {/* <span className={classes.sensorVal}>{lastVal}%</span>
                    <CircularProgress size={100} thickness={7} variant="determinate" value={lastVal} /> */}
                </div>
            </Grid>
            )
            else return (
            <Grid item xs={12} sm={6} key={sensor._id}>
                <Typography variant="body1" className={classes.sensorTitle}>
                    {sensor.title}
                </Typography>
                <Typography variant="h2" color="primary">{lastVal}{unity}</Typography>
            </Grid>
            )
        })
        }
        </Grid> 
    </Paper>
  )
}
