import './App.css';
import Navigation from './components/client/navigation';
import AdminNavigation from './components/admin/navigation';
import Login from './components/login';
import { Route, Routes, Navigate } from 'react-router-dom';
import {ThemeProvider, createTheme } from '@material-ui/core';
import { useEffect } from 'react';
import Logout from './components/logout';
import ResetPassword from './components/reset-password';
import ForgotPassword from './components/forgot-password';

const theme = createTheme({
  palette: {
      secondary: {
        main: '#31BC85'
      },
      primary: {
        main: '#105157'
      },
      black: {
        main: '#1A1A1A'
      },
      lightgray: {
        main: '#F4F4F4'
      },
      gray: {
        main: '#aaa'
      },
      white: {
        main: '#FFFFFF'
      }
  },
});

function App() {
  //const user = JSON.parse(localStorage.getItem('user'));
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {/* <Route path="/admin/*" element={
          (user !== null && user !== undefined)
          ? user.user.roles[0] === 'admin'
            ? <AdminNavigation /> 
            : <Navigation /> 
          : <Navigate to='/login' />
        } />
        <Route path="/*" element={user === null ? <Login /> : <Navigation /> } /> */}   
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/*" element={<Navigation/>} />
        <Route path="/admin/*" element={<AdminNavigation/>} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
