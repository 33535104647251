import innoApi from '../../apis/innoApi';
import {ActionTypes} from '../constants/action-types';

export const fetchSensorsByZone = (zone) => {
    return async (dispatch) => {
        const response = await innoApi.get(`sensors/zone/${zone}`);
        dispatch({type: ActionTypes.FETCH_SENSORS_BY_ZONE, payload: response});
    }
}

export const fetchSensorsByField = (field) => {
    return async (dispatch) => {
        const response = await innoApi.get(`sensors/field/${field}`);
        dispatch({type: ActionTypes.FETCH_SENSORS_BY_FIELD, payload: response});
    }
}

export const fetchSensors = () => {
    return async (dispatch) => {
        const response = await innoApi.get('sensors');
        dispatch({type: ActionTypes.FETCH_SENSORS, payload: response});
    }
}

export const addSensor = (sensor) => {
    return async (dispatch) => {
        const response = await innoApi.post('sensors/', sensor);
        dispatch({type: ActionTypes.ADD_SENSOR, payload: response});
    }
}

export const editSensor = (id, sensor) => {
    return async (dispatch) => {
        const response = await innoApi.put(`sensors/${id}`, sensor);
        dispatch({type: ActionTypes.EDIT_SENSOR, payload: response});
    }
}

export const deleteSensor = (id) => {
    return async (dispatch) => {
        const response = await innoApi.delete(`sensors/${id}`);
        dispatch({type: ActionTypes.DELETE_SENSOR, payload: response});
    }
}

export const addToLora = (lora, sensorId) => {
    return async () => { await innoApi.put(`lora/add/sensor/${lora}/${sensorId}`) }
}

export const removeFromLora = (lora, sensorId) => {
    return async () => { await innoApi.put(`lora/remove/sensor/${lora}/${sensorId}`) }
}