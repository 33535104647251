import React, { useEffect, useState } from 'react';
import { Avatar, Button, CssBaseline, 
  TextField, Box, Typography, Container, CircularProgress, Grid, Link
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import useStyles from './style';
import axios from 'axios';
import { Alert } from '@material-ui/lab';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <a href="https://mui.com/">
        Innovega
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const ForgotPassword = () => {
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);
  const [alert, setAlert] = useState({isOpen: false, type: '', message: ''})
  
  useEffect(()=> {})
  useEffect(()=>{
    alert.isOpen && setTimeout(() => {
      setAlert({isOpen: false, type: '', message: ''})
    }, "3000")
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    if(e.target.email.value && e.target.email.value!=='') {
      setClicked(true);
      const resetBody = {
        email: e.target.email.value
      };
      axios.post(`${process.env.REACT_APP_BASE_URL}/auth/forgot-password`,resetBody)
      .then((res)=>{
        if (res.data && (res.status===200 || res.status === 201)) {
          setAlert({
                isOpen: true,
                type: 'success',
                message: res.data.message
          })
        }
        setClicked(false)
      })
      .catch((err)=>{
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Something went wrong, please try again'
    })
      })
    } else {
      setAlert({
        isOpen: true,
        type: 'error',
        message: 'Email is required'
      })
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      {alert.isOpen && (
        <Alert variant="filled" severity={alert.type} onClose={()=>{setAlert({isOpen: false})}} className={classes.alert}>
          {alert.message}
        </Alert>
      )}
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          {!clicked
          ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Send
            </Button>
          )
          : (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <CircularProgress />
            </div>
          )
          }
        </form>
        <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2">
                Login
              </Link>
            </Grid>
        </Grid>
      </div>
      <Box className={classes.copyrightBox}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default ForgotPassword;