import { Button, FormControl, FormControlLabel, FormGroup, Grid, Input, InputAdornment, InputLabel, Paper, Switch, TextField, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { editLora } from '../../../redux/actions/loraActions';
import { fetchZones } from '../../../redux/actions/zoneActions';
import { NewMap } from './mapNew';
import useStyles from './style';

const EditLora = ({showIt, lora, setAlert}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const zones = useSelector(state => state.zones.zones.data)
  const capitalize = (word) => (word.charAt(0).toUpperCase()+word.slice(1))
  
  const [title, setTitle] = useState(lora.title);
  const [selectedZone, setSelectedZone] = useState({ value: lora.zone, label: lora.zone.title + ' ('+ lora.zone.field.title + ' - ' + lora.zone.field.user.firstname + ')' });
  const [macAddr, setMacAddr] = useState(lora.macAddr);
  const [type, setType] = useState({ value: lora.type, label: capitalize(lora.type)});
  const [device, setDevice] = useState({ value: lora.device, label: capitalize(lora.device)});
  const [isActive, setIsActive] = useState(lora.isActive);
  const [markerPos, setMarkerPos] = useState(lora.position.coordinates);

  useEffect(() => {
    dispatch(fetchZones());
  }, [zones]);

  const zoneOptions = [];
  zones && zones.map((zone) => {
    let zoneLabel = zone.title + ' ('+ zone.field.title + ' - ' + zone.field.user.firstname + ')'
    let option = { value: zone, label: zoneLabel };
    zoneOptions.push(option);
  });

  const typeOptions = [
    { value: 'node', label: 'Node' },
    { value: 'gateway', label: 'Gateway' }
  ]

  const deviceOptions = [
    { value: 'sensor', label: 'Sensors' },
    { value: 'valve', label: 'Vannes' },
    { value: 'pump', label: 'Pump' },
    { value: 'gwy', label: 'Gateway' }
  ]

  const handleSubmit = (e) => {
    e.preventDefault();
    const loraToUpdate = {
      title: e.target.title.value,
      zone: selectedZone.value,
      zoneId: selectedZone.value?._id,
      field: selectedZone.value?.field._id,
      user: selectedZone.value?.field.user._id,
      macAddr: e.target.macAddr.value,
      type: type.value,
      device: device.value,
      isActive: isActive,
      position: {type: 'Point', coordinates: markerPos},
    }
    dispatch(editLora(lora._id, loraToUpdate));
    showIt(false);
    setAlert({
      isOpen: true,
      type: 'success',
      message: 'Lora Box edited successfully !'
    })
  }

  return (
    <div>
      <Paper style={{ padding: 30 }}>
        <Typography variant="h5" color="primary" style={{ marginBottom: 30 }} >
          Edit Field
        </Typography>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField id="title" name="title" label="Box Title" fullWidth value={title} onChange={(e)=>setTitle(e.target.value)}/>
            </Grid>
            <Grid item xs={12}>
              <Select
                className={classes.select}
                placeholder="Select Zone"
                defaultValue={selectedZone}
                onChange={setSelectedZone}
                options={zoneOptions}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField id="macAddr" name="macAddr" label="MAC Address" fullWidth inputProps={{maxLength:2}} value={macAddr} onChange={(e)=>setMacAddr(e.target.value)}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                className={classes.selecttype}
                placeholder={'Type'}
                defaultValue={type}
                onChange={setType}
                options={typeOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                className={classes.selecttype}
                placeholder={'Device'}
                defaultValue={device}
                onChange={setDevice}
                options={deviceOptions}
              />
            </Grid>
            {selectedZone && (
              <Grid item xs={12}>
                <NewMap field={selectedZone.value.field} setMarkerPos={setMarkerPos} markerPos={markerPos} />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={isActive} onChange={() => setIsActive(!isActive)} name="isActive" color="primary" />}
                  label="Active"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              type="submit"
            >
              Save
            </Button>
            <Button variant="contained" onClick={() => showIt(false)} style={{ marginRight: 20 }}>
              Cancel
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  )
}

export default EditLora;