import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    mainFeaturedPost: {
        maxHeight: 250,
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(14),
          paddingRight: 0,
        },
    },
    primaryMainText: {
        color: theme.palette.primary.main
    },
    sensorsPaper: {
        padding: theme.spacing(4),
        textAlign: 'center'
    },
    sensorVal: {
        position: 'absolute', 
        top: '36px', left: '46%',
        color: theme.palette.primary.main,
        fontSize: '18px',
        fontWeight: 'bold'
    },
    sensorTitle: {
        margin: theme.spacing(4),
        fontWeight: 'bold'
    },
    addPeriodBtn: {
        color: '#FFF', 
        cursor: 'pointer',
        backgroundColor: '#105157', 
        marginBottom: 16, 
        width: 36, 
        height: 36, 
        padding: 6, 
        borderRadius: 10,
        boxShadow: '1px 2px 7px #777'
    },
    periodRow: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'flex-start', 
        justifyContent: 'space-between', 
        marginBottom: 8,
        marginLeft: 16,
        paddingBottom: 8
    },
    periodRowHasBorder: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'flex-start', 
        justifyContent: 'space-between', 
        marginBottom: 8,
        marginLeft: 16,
        paddingBottom: 8,
        borderBottomColor: 'lightgray', 
        borderBottomWidth: 1, 
        borderBottomStyle: 'solid'
    },
    periodLeft: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
    }
}));

export default useStyles;