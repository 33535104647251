import React from 'react'

import L from 'leaflet';
import { MapContainer, Marker, Polygon, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const Map = ({fields}) => {

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  const myLatLng = [36.4, 9.835];

  return (
    <MapContainer
      center={myLatLng}
      zoom={8.5}
      style={{width: '100%', height: 700, borderRadius: 5}}
    >
      <TileLayer
        url='https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=C3lguWuPKbIT8I8tTO7t'
        attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
      />
      {fields && fields.length>0 && fields.map((field)=> field.geoLimits.length>0 && (
        <Marker position={field.geoLimits[0].coordinates} key={field._id}>
          <Popup>
            <b>{field.title}</b><br/>
            {field.user.firstname} {field.user.lastname}<br/>
            {field.state}, {field.city} <br/>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <span>{field.area}Ha</span>
              <b>({field.isActive? ('Active') : ('Blocked')})</b>
            </div>
          </Popup>
        </Marker>
      ))}
      
      {/* <Polygon
        pathOptions={{
          fillColor: '#18b272',
          fillOpacity: 0.7,
          weight: 2,
          opacity: 1,
          dashArray: 3,
          color: 'white'
        }}
        positions={coordinates}
      /> */}
    </MapContainer>
  )
}
export default Map;