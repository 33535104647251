import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/userActions';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(()=>{
    dispatch(logout());
    localStorage.setItem('user', null);
    navigate('/login');
  },[])
  return (
    <div></div>
  );
}

export default Logout;