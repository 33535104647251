import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    flexRight: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    between: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    newButton: {
        marginBottom: theme.spacing(3),
    },
    paperPad: {
        paddingLeft: 30,
        paddingRight: 30
    },
    actionIcons: {
        display: 'flex',
        flexDirection: 'row',
    },
    actionIcon: {
        marginRight: 10,
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    modalPaper: {
        marginLeft: '40%',
        marginTop: '10%',
        width: 400,
        borderRadius: 3,
        outline:'none',
        backgroundColor: 'white',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    mainContainer: {
        marginBottom: 20
    },
    switchMap: {
        display: 'flex', 
        flexDirection: 'row-reverse',
        marginBottom: 20
    },
    modalBtn: {
        marginLeft: 10
    },
    formActions: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 30
    },
    detailsBgImg: {
        height: 200
    },
    avatarWrap: {
        marginTop: -100,
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    userAvatar: {
        width: 200,
        height: 200
    },
    detailsTitle: {
        textAlign: 'center',
        marginBottom: 30
    },
    detailsUser: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    detailsContent: {
        textAlign: 'start'
    },
    infoRow: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        marginBottom: 20
    },
    infoText: {
        marginLeft: 20
    },
    infoBlock: {
        marginLeft: 20,
        flexDirection: 'column'
    },
    margin: {
        marginLeft: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    mapContainer: {
        width: 1000,
        height: 500
    },
    alert: {
        marginBottom: 20
    }
}));

export default useStyles;