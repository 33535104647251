import React, { useEffect, useState } from 'react'
import { Button, Checkbox, FormControlLabel, Grid, Switch, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import Modal from 'react-modal';
import useStyles from './style';
import { v4 as uuid } from 'uuid';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import innoApi from '../../../../apis/innoApi';

const customStyles = {
    content: {
      width: '50%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderWidth: 0,
      borderRadius:5,
    },
    overlay: {
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 1300
    }
};

const Schedule = ({openPlanif, setOpenPlanif, vannes, setAlert}) => {
    const classes = useStyles();
    const [startTime, setStartTime] = useState(new Date());
    const [stopTime, setStopTime] = useState(new Date());
    const [repeat, setRepeat] = useState(false);
    const [forever, setForever] = useState(true);
    const [daily, setDaily] = useState(false);
    const [days, setDays] = useState([]);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [allVannes, setAllVannes] = useState(true);
    const [progVannes, setProgVannes] = useState(vannes ? vannes.map((vanne)=>(vanne._id)): []);

    const reset = () => {
        setStartTime(new Date());
        setStopTime(new Date());
        setRepeat(false);
        setForever(true);
        setDaily(false);
        setDays([]);
        setFromDate(new Date());
        setToDate(new Date());
        setAllVannes(true);
        setProgVannes([]);
    };

    useEffect(()=>{
        setProgVannes(vannes ? vannes.map((vanne)=>(vanne._id)): [])
    },[openPlanif])
    const initDays = [
        {num: 0, name: 'Dim'},
        {num: 1, name: 'Lun'},
        {num: 2, name: 'Mar'},
        {num: 3, name: 'Mer'},
        {num: 4, name: 'Jeu'},
        {num: 5, name: 'Ven'},
        {num: 6, name: 'Sam'}
    ]

    const handleStart = (time) => {
        setStartTime(time);
    };
    const handleStop = (time) => {
        setStopTime(time);
    };
    const handleStartDate = (e) => {
        setFromDate(new Date(e.target.value));
    };
    const handleStopDate = (e) => {
        setToDate(new Date(e.target.value));
    };
    const addDay = (day) => {
        setDays(days => [...days, day]);
    }
    const addVanneId = (id) => {
        setProgVannes(progVannes => [...progVannes, id]);
    }
    const handleCheckAllVannes = (e) => {
        if (e.target.checked) {
            setAllVannes(true);
            setProgVannes([]);
            setProgVannes(vannes.map((vanne)=>vanne._id))
        } else {
            setAllVannes(false);
            setProgVannes([]);
        }
        
    }

    const changeTimeZone = (date, timeZone) => {
        return new Date(
            date.toLocaleString('en-US', {
              timeZone,
            }),
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        progVannes.length>0 &&
        progVannes.map((vanneId) => {
            let period = {
                "_id": uuid(),
                "isActive": true,
                "forever": forever,
                "repeat": repeat,
                "dayly": daily,
                "days": days,
                "from": changeTimeZone(startTime, 'Libya').toISOString(),
                "to": changeTimeZone(stopTime, 'Libya').toISOString(),
                "fromDate": fromDate,
                "toDate": toDate
            }
            innoApi.put(`vannes/periods/${vanneId}`, period)
            .then((res)=>{
                setOpenPlanif(false);
                if(res.status === 200 || res.status === 201) {
                    setAlert({ isOpen: true, type: 'success', message: 'Irrigation planifiée avec succès' })
                } else {
                    setAlert({ isOpen: true, type: 'error', message: 'Une erreur s\'est produite.' })
                }
                reset();
            })
        })
    }

  return (
    <Modal
        isOpen={openPlanif}
        onRequestClose={()=>setOpenPlanif(false)}
        style={customStyles}

    >
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: 20}}>
            <Typography className={classes.subtitle} variant="h5" color="primary">
                Planifier votre irrigation
            </Typography>
            <CloseIcon
                fontSize='small'
                onClick = {()=> setOpenPlanif(false)}
                style={{cursor: 'pointer', color: '#333'}}
            />
        </div>
        <Grid container spacing={2} style={{alignItems: 'flex-end'}}>
            <Grid item sm={4} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Début Irrigation"
                    value={startTime}
                    onChange={handleStart}
                    KeyboardButtonProps={{'aria-label': 'change time'}}
                />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item sm={4} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Fin Irrigation"
                    value={stopTime}
                    onChange={handleStop}
                    KeyboardButtonProps={{'aria-label': 'change time'}}
                />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4} style={{display: 'inline-flex', alignItems: 'center', justifyContent:'flex-end'}}>
                <FormControlLabel
                    control={<Switch checked={repeat} name={'repeat'} color='primary' onChange={()=>setRepeat(!repeat)}/>}
                    label="Répeter"
                />
            </Grid>
        </Grid>
        <Typography variant='body1' style={{color: '#555', marginTop: 16}}>Choisissez les vannes à appliquer</Typography>
        <Grid item xs={12} sm={12} style={{display: 'inline-flex'}}>
            <Checkbox color='primary' checked={allVannes} onChange={handleCheckAllVannes} />
            <Typography variant='body2' style={{marginTop: 10}}>Toutes les vannes</Typography>
        </Grid>
        <Grid container spacing={2} style={{alignItems: 'center'}}>
        {vannes && vannes.map((vanne)=>(
            <Grid item xs={12} sm={4} key={vanne._id} style={{display: 'inline-flex'}}>
                <Checkbox disabled={allVannes} color='primary' onChange={()=>addVanneId(vanne._id)} />
                <Typography variant='body2' style={{marginTop: 10}}>{vanne.title}</Typography>
            </Grid>
        ))}
        </Grid>
        {repeat && (
            <>
                <Typography variant='subtitle1' color="primary" style={{marginTop: 20, marginBottom: 10, fontWeight:'500'}}>Répétitions</Typography>
                <Typography variant='body1' style={{color: '#555'}}>Choisissez les jours d'irrigation</Typography>
                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                    <FormControlLabel
                        control={<Switch checked={daily} name={'daily'} color='primary' onChange={()=>setDaily(!daily)}/>}
                        label="Tous les jours"
                    />
                </div>
                {!daily && (
                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                    {initDays.map((initDay)=>(
                    <div key={initDay.num} style={{display: 'inline-flex', marginRight: 20}}>
                        <Checkbox color='primary' onChange={()=>addDay(initDay.num)} />
                        <Typography variant='body2' style={{marginTop: 10}}>{initDay.name}</Typography>
                    </div>
                    ))}
                </div>
                )}
                <Typography variant='body1' style={{color: '#555', marginTop:10, marginBottom: 8}}>Choisissez la période d'irrigation</Typography>
                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                    <FormControlLabel
                        control={<Switch checked={forever} name={'forever'} color='primary' onChange={()=>setForever(!forever)}/>}
                        label="Sans Limite"
                    />
                </div>
                {!forever && (
                <Grid container spacing={2} style={{alignItems: 'flex-end'}}>
                    <Grid item sm={4} xs={12}>
                    <TextField
                        id="date"
                        label="Date Début"
                        type="date"
                        onChange={handleStartDate}
                        InputLabelProps={{shrink: true}}
                    />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                    <TextField
                        id="date"
                        label="Date Fin"
                        type="date"
                        onChange={handleStopDate}
                        InputLabelProps={{shrink: true}}
                    />
                    </Grid>
                </Grid>
                )}
            </>
        )}
        <div style={{display: 'flex', flex: 1, flexDirection: 'row-reverse'}}>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AlarmOnIcon />}
                type="button"
                style={{marginTop: 20}}
                onClick={handleSubmit}
            >
                Ajouter
            </Button>
        </div>
    </Modal>
  )
}

export default Schedule