import { ActionTypes } from "../constants/action-types"

const initialState = {
    vannes: []
}

export const vannesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_VANNES:
      return { ...state, vannes: payload };
    case ActionTypes.FETCH_VANNES_BY_ZONE:
      return { ...state, vannes: payload };
    default:
      return state;
  }
};

export const vanneReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ActionTypes.ADD_VANNE:
      return {...state, ...payload};
    case ActionTypes.OPEN_CLOSE_VANNE:
      return {...state, ...payload};
    case ActionTypes.EDIT_VANNE:
      return {...state, ...payload};
    case ActionTypes.DELETE_VANNE:
      return {...state, ...payload};
    case ActionTypes.DELETE_PERIOD:
      return {...state, ...payload};
    default:
      return state;
  }
};