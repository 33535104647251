import React, { useEffect, useState } from 'react';
import { Avatar, Button, CssBaseline, 
  TextField, Box, Typography, Container, CircularProgress, Link, Grid
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import useStyles from './style';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/userActions';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <a href="https://mui.com/">
        Innovega
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(()=>{
    if(user === null || typeof(user) === 'undefined') {
      dispatch(logout());
      localStorage.setItem('user', null);
      navigate('/login');
    }
  },[])

  const handleSubmit = (e) => {
    e.preventDefault();
    setClicked(true);
    const user = {
      username: e.target.email.value,
      password: e.target.password.value,
    };
    axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`,user)
    .then((res)=>{
      localStorage.setItem('user', JSON.stringify(res.data));
      res.data.user && res.data.user.roles[0] === 'admin' ? navigate('/admin') : navigate('/');
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {!clicked
          ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          )
          : (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <CircularProgress />
            </div>
          )
          }
          <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box className={classes.copyrightBox}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default Login;