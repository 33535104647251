import { Button, FormControl, FormControlLabel, FormGroup, 
        Grid, Input, InputAdornment, InputLabel, Paper, 
        Switch, TextField, Typography 
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './style';
import Select from 'react-select';
import { fetchZones } from '../../../redux/actions/zoneActions';
import { addToLora, addVanne } from '../../../redux/actions/vanneActions';
import { fetchLorasByZone } from '../../../redux/actions/loraActions';

const NewVanne = ({ showIt, setAlert }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(true);
  const [selectedZone, setSelectedZone] = useState();
  const [selectedLora, setSelectedLora] = useState();
  const [openTranslation, setOpenTranslation] = useState(false);
  const addedVanne = useSelector(state => state.vanne);
  useEffect(()=>{ 
    addedVanne && selectedLora && dispatch(addToLora(selectedLora.value?._id ,addedVanne?.data?._id));
  }, [addedVanne])
  
  const zones = useSelector(state => state.zones.zones.data);
  useEffect(() => {
    dispatch(fetchZones());
  }, []);

  const loras = useSelector(state => state.loras.loras.data);
  useEffect(()=>{
    selectedZone && dispatch(fetchLorasByZone(selectedZone.value._id));
  },[selectedZone]);

  const zoneOptions = [];
  zones && zones.map((zone) => {
    let zoneLabel = zone.title + ' | ' + zone.field.title;
    let option = { value: zone, label: zoneLabel };
    zoneOptions.push(option);
  });

  const loraOptions = [];
  loras && loras.map((lora) => {
    let loraLabel = lora.title + ' (' + lora.device + ')';
    let option = { value: lora, label: loraLabel };
    loraOptions.push(option);
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let lorabox = selectedLora && selectedLora.value ? {
      id: selectedLora.value._id,
      title: selectedLora.value.title,
      macAddr: selectedLora.value.macAddr,
      connected: selectedLora.value.connected,
      device: selectedLora.value.device,
      position: selectedLora.value.position,
      isActive: selectedLora.value.isActive
    } : null;
    const vanneToAdd = {
      title: e.target.title.value,
      title_tr: {
        en: e.target.title.value,
        fr: e.target.title_fr?.value,
        ar: e.target.title_ar?.value,
      },
      zone: selectedZone.value,
      zoneId: selectedZone.value._id,
      field: selectedZone.value.field._id,
      lorabox: lorabox,
      loraboxId: selectedLora?.value._id,
      description: e.target.description.value,
      description_tr: {
        en: e.target.description.value,
        fr: e.target.description_fr?.value,
        ar: e.target.description_ar?.value,
      },
      brand: e.target.brand.value,
      diameter: e.target.diameter.value,
      debit: e.target.debit.value,
      qty_pd: e.target.qty_pd.value,
      nb_pd: e.target.nb_pd.value,
      hose: {
        diameter: e.target.h_diameter.value,
        nbGouteur: e.target.nbGouteur.value,
        debit_pg: e.target.debit_pg.value
      },
      isActive: isActive
    }
    dispatch(addVanne(vanneToAdd));
    //showIt(false);
    setAlert({
      isOpen: true,
      type: 'success',
      message: 'New vanne added successfully !'
    })
  }

  return (
    <div>
      <Paper style={{ padding: 30 }}>
        <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
          <Typography variant="h5" color="primary" style={{ marginBottom: 30 }} >
            New Vanne
          </Typography>
          <Button onClick={()=>setOpenTranslation(!openTranslation)}><GTranslateIcon color='primary' /></Button>
        </div>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField id="title" name="title" label="Vanne Title" fullWidth />
            </Grid>
            {openTranslation && (<>
            <Grid item xs={12} md={6}>
              <TextField name="title_fr" label="Vanne Title (FR)" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="title_ar" label="Vanne Title (AR)" fullWidth />
            </Grid>
            </>)}
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <Typography color='textSecondary'>Zone</Typography>
                <Select
                  onChange={setSelectedZone}
                  options={zoneOptions}
                />
              </FormControl>
            </Grid>
            {selectedZone && (
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <Typography color='textSecondary'>LoRa Box</Typography>
                <Select
                  defaultValue={selectedLora}
                  onChange={setSelectedLora}
                  options={loraOptions}
                />
              </FormControl>
            </Grid>
            )}
            <Grid item xs={12}>
              <TextField id="description" name="description" label="Description" fullWidth multiline />
            </Grid>
            {openTranslation && (<>
            <Grid item xs={12}>
              <TextField name="description_fr" label="Description (FR)" fullWidth multiline />
            </Grid>
            <Grid item xs={12}>
              <TextField name="description_ar" label="Description (AR)" fullWidth multiline />
            </Grid>
            </>)}
            <Grid item xs={12} md={4}>
              <TextField id="brand" name="brand" label="Brand" fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Diameter</InputLabel>
                <Input id="diameter" name="diameter" type='number'
                  endAdornment={
                    <InputAdornment position="end">
                      cm
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Debit</InputLabel>
                <Input id="debit" name="debit" type='number'
                  endAdornment={
                    <InputAdornment position="end">
                      l/m
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Qty/Day</InputLabel>
                <Input id="qty_pd" name="qty_pd" type='number'
                  endAdornment={
                    <InputAdornment position="end">
                      Liter
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField id="nb_pd" name="nb_pd" label="Times/Day" type='number' fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Hose</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Hose Diameter</InputLabel>
                <Input id="h_diameter" name="h_diameter" type='number'
                  endAdornment={
                    <InputAdornment position="end">
                      cm
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField id="nbGouteur" name="nbGouteur" label="Nb Gouteurs" type='number' fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Debit / Gouteur</InputLabel>
                <Input id="debit_pg" name="debit_pg" type='number'
                  endAdornment={
                    <InputAdornment position="end">
                      l/m
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={isActive} onChange={() => setIsActive(!isActive)} name="isActive" color="primary" />}
                  label="Active"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              type="submit"
            >
              Save
            </Button>
            <Button variant="contained" onClick={() => showIt(false)} style={{ marginRight: 20 }}>
              Cancel
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  )
}

export default NewVanne;