import { Avatar, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailIcon from '@material-ui/icons/Mail';
import React, { useEffect, useState } from 'react';
import bgImg from './img/bg-nature.jpg';
import useStyles from './style';
import avatarImg from './img/user-avatar.jpg';

export const UserDetails = ({showIt, selectedUser}) => {
  const classes = useStyles();
  const [user, setUser] = useState(selectedUser);
  useEffect(()=>{
      setUser(selectedUser);
  },[selectedUser])
  return (
    <Card>
      <CardActionArea>
        <CardMedia
          className={classes.detailsBgImg}
          image={bgImg}
        />
        <div className={classes.avatarWrap}>
            <Avatar alt={user.firstname} src={user.image ?  `https://innovega-server.herokuapp.com/users/image/${user.image}` : avatarImg} style={{width: 200, height: 200}}/>
        </div>
        <CardContent>
          <div className={classes.detailsTitle}>
            <Typography gutterBottom variant="h5" component="h2">
                {user.firstname} {user.lastname}
            </Typography>
            <Typography variant="h6" color="textSecondary" style={{textTransform: 'capitalize'}}>
                {user.roles[0]}
            </Typography>
          </div>
          <div className={classes.detailsContent}>
              <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <div className={classes.infoRow}>
                        <MailIcon color='primary' />
                        <Typography className={classes.infoText} variant="body1">{user.email}</Typography>
                    </div>
                    <div className={classes.infoRow}>
                        <LocationOnIcon color='primary' />
                        <div className={classes.infoBlock}>
                            <Typography  variant="body1">
                                {user.country}, {user.state}
                            </Typography>
                            <Typography  variant="body1">
                                {user.city} {user.postcode}, {user.address}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.infoRow}>
                        <PhoneIcon color='primary' />
                        <Typography className={classes.infoText} variant="body1">{user.phone}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" color={`${user.isActive ? ('primary') : ('textSecondary')}`}>
                        {user.isActive ? ('Active') : ('Blocked')}
                    </Typography>
                  </Grid>
              </Grid>
            
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={()=>showIt(false)}>
          Close
        </Button>
      </CardActions>
    </Card>
  )
}
