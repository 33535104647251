import { ActionTypes } from "../constants/action-types"

const initialState = {
    fields: []
}

export const fieldsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_FIELDS:
      return { ...state, fields: payload };
    default:
      return state;
  }
};

export const fieldReducer = (state = {}, {type, payload}) => {
  switch(type) {
    case ActionTypes.EDIT_FIELD || ActionTypes.ACTIVATE_FIELD || ActionTypes.DELETE_FIELD:
      return { ...state, ...payload};
    default:
      return state;
  }
}