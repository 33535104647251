import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { logout } from '../../../redux/actions/userActions';
import Weather from '../weather';
import Zones from '../zones';
import Zone from '../zones/details';

export default function Router() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(()=>{
    if(user === null || typeof(user) === 'undefined') {
      dispatch(logout());
      localStorage.setItem('user', null);
      navigate('/login');
    }
  },[]);
  
  return (
    <Routes>
        <Route path="/" element={<Zones />} />
        <Route path="zones" element={<Zones />} />
        <Route path="zones/:id" element={<Zone />} />
        <Route path='weather' element={<Weather />} />
    </Routes>
  )
}
